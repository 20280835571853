
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { StripeElements, StripeElement } from 'vue-stripe-js'

import { STRIPE_PUBLIC_KEY, IS_UNIT_TESTING } from '@/init/settings'
import { ValidationState } from '@/helpers/form'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import {
  stripeLinkIntegration13752Experiment,
  stripePaymentOptions15213,
} from '@/services/ab'

import { BillingView } from '@/models/billing'
import FormError from '@/components/ui/FormError.vue'
import { wait } from '@/helpers/vue-wait'

import vLoading from 'vue-wait/src/components/v-wait.vue'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'

@Options({
  components: {
    StripeElements,
    StripeElement,
    FormError,
    'v-wait': vLoading,
    LoadingSpinner,
  },
})
export default class StripeCard extends Vue {
  @Prop() private billing!: BillingView
  @Prop({ default: true }) private showButton!: boolean
  @Prop({ default: false }) private startABExperiment!: boolean

  validation: ValidationState = new ValidationState()

  // True once card data has been changed
  private changed: boolean = false
  // True once Stripe reports the the card is valid (but we din't save it yet).
  private complete: boolean = false
  // True while we are saving card data.
  private saving: boolean = false
  // True once we successfully saved card, tirggers the success alert.
  private saved: boolean = false

  // https://stripe.com/docs/js/initializing#init_stripe_js-options
  private instanceOptions: any = {}
  // https://stripe.com/docs/stripe.js#element-options
  private cardOptions: any = {
    style: {
      base: { fontSize: '16px', color: '#32325d' },
    },
  }

  public enablePaymentElement = false

  // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
  private elementsOptions: any = {
    // We can pass additional options to customize card element style,
    // see https://stripe.com/docs/stripe.js#element-options for details.
    // Note: for some reason 'appearance' does not work here, but
    // 'style' in cardOptions does.
    // appearance: {
    //   variables: { fontSizeBase: '16px', colorPrimary: '#32325d' },
    // },
  }

  async beforeMount(): Promise<void> {
    this.enablePaymentElement = await stripePaymentOptions15213()
    if (this.enablePaymentElement) {
      this.elementsOptions.mode = 'setup'
      this.elementsOptions.currency = 'usd'
    }

    if (this.startABExperiment) {
      wait.start(this, 'ABExperimentRequest')
      // We have to invert the value here because the experiment specs say that we
      // _enable_ the Link integration when the experiment value is `yes`, but the card
      // option is to _disable_ the integration.
      // This is a little awkward in the code, but it should help avoid confusion
      // in the experiment analysis when it's done.
      this.cardOptions.disableLink =
        !(await stripeLinkIntegration13752Experiment())
      wait.end(this, 'ABExperimentRequest')
    }
  }

  changedHandler($event: any): void {
    this.changed = true
    this.complete = $event.complete
  }

  needsSaving(): boolean {
    return this.changed
  }

  async paymentElementConfirmStripe(): Promise<any> {
    const setupIntent = await backend.getSetupIntent()
    // @ts-ignore
    const confirmSetupFn = this.$refs.elms.instance.confirmSetup
    const params = {
      // @ts-ignore
      elements: this.$refs.elms.elements,
      clientSecret: setupIntent.client_secret,
      redirect: 'if_required',
      confirmParams: {
        return_url: 'https://www.shortform.com/app/billing#',
      },
    }
    // @ts-ignore
    this.$refs.elms.elements.submit()
    const { error } = await confirmSetupFn(params)
    if (error) {
      console.log(error)
      Sentry.captureException(error)
      return { error: error }
    }
    return { setupIntent: setupIntent }
  }

  async cardConfirmStripe(): Promise<any> {
    // @ts-ignore
    const cardElement = this.$refs.card.stripeElement
    // @ts-ignore
    let createTokenFn = this.$refs.elms.instance.createToken
    if (IS_UNIT_TESTING) {
      // Temporary: mock createToken here (TODO: mock it in tests code)
      createTokenFn = (window as any).Stripe_createToken
    }
    // The createToken returns a Promise which resolves in a result object with
    // either a token or an error key.
    // See https://stripe.com/docs/api#tokens for the token object.
    // See https://stripe.com/docs/api#errors for the error object.
    // More general https://stripe.com/docs/stripe.js#stripe-create-token.
    const data = await createTokenFn(cardElement)
    if (data.error) {
      // Something went wrong on Stripe side.
      const expected_errors = [
        'incomplete_zip',
        'incomplete_cvc',
        'incomplete_expiry',
        'incomplete_number',
        'invalid_number',
        'invalid_expiry_month_past',
      ]
      if (data.error.code && expected_errors.indexOf(data.error.code) === -1) {
        // Unexpected error, send to Sentry.
        Sentry.withScope((scope) => {
          scope.setExtra('stripeData', data)
          Sentry.captureMessage(data.error.message)
        })
      }
    }
    return data
  }

  /**
   * Save card, returns true on success
   */
  async save(): Promise<boolean> {
    this.saving = true

    try {
      if (this.enablePaymentElement) {
        const data = await this.paymentElementConfirmStripe()
        if (data.error) {
          this._setSaved(data.error)
          return false
        }
        await backend.savePaymentMethod({ id: data.setupIntent.id })
      } else {
        const data = await this.cardConfirmStripe()
        if (data.error) {
          this._setSaved({
            response: {
              status: 400,
              data: { errors: { card: [data.error.message] } },
            },
          })
          return false
        }
        await backend.saveCard(data.token)
        this.billing.card_data = data.token['card']
      }
      this._setSaved('')

      // There is a new billing card data returned by stripe,
      // if we saved it successfully, update this.billing,
      // so the new card is displayed.
      this.$emit('saved')
      return true
    } catch (error) {
      this._setSaved(error)
    }
    return false
  }

  /**
   * Update state flags depending on the error value.
   */
  _setSaved(error: any): void {
    if (error) {
      this.saving = false
      this.saved = false
      this.changed = true
      this.validation.showErrors(error)
    } else {
      this.saving = false
      this.saved = true
      this.changed = false
      this.validation.reset()
    }
  }

  get stripeKey(): string {
    return STRIPE_PUBLIC_KEY
  }

  get savedAlert(): number | boolean {
    if (this.saved) {
      // 3 seconds to hide the alert
      return 3
    }
    return false
  }
}

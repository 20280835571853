import { GOOGLE_CLIENT_ID } from '@/init/settings'
import { GoogleAuth, User } from '@codetrix-studio/capacitor-google-auth'

import { ValidationState } from '@/helpers/form'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import { platform } from '@/services/platform'

import { afterAuth } from './logic'
import { SocialAuthView } from './social'
import { OnAuthStart, OnAuthSuccess, OnAuthError } from './models'

/*
 * Handle Google authentication API response in the native app.
 *
 * See https://github.com/CodetrixStudio/CapacitorGoogleAuth
 */
export class GoogleNativeAuthView implements SocialAuthView {
  public validation: ValidationState

  public onStart: OnAuthStart
  public onSuccess: OnAuthSuccess
  public onError: OnAuthError

  isEnabled: boolean = true

  constructor(
    validation: ValidationState,
    onStart: OnAuthStart,
    onSuccess: OnAuthSuccess,
    onError: OnAuthError,
  ) {
    this.validation = validation
    this.onStart = onStart
    this.onSuccess = onSuccess
    this.onError = onError
  }

  /**
   * Intialize native GoogleAuth plugin.
   */
  public init(): void {
    GoogleAuth.initialize({
      clientId: GOOGLE_CLIENT_ID,
      // Configuration example:
      // boolean, default false, Set if your application needs to refresh
      // access tokens when the user is not present at the browser.
      // grantOfflineAccess: true,
      //
      // Scopes that you might need to request to access Google APIs
      // https://developers.google.com/identity/sign-in/web/incremental-auth#step_1_request_base_scopes
      // https://developers.google.com/identity/protocols/oauth2/scopes#oauth2
      scopes: ['profile', 'email'],
    })
  }

  /**
   * Login with Google.
   */
  async start(): Promise<void> {
    try {
      this.onStart()
      const response: User = await GoogleAuth.signIn()
      const isIOS = await platform.isNativeIOSApp()

      const email = response.email
      const googleToken = response.authentication.idToken
      const model = { email: email, googleToken: googleToken }

      const authData = await backend.googleLoginOrRegister(model, isIOS)

      authData.user.full_name = response.givenName

      // Redirect the user to the app
      const nextPage = await afterAuth(authData)

      return this.onSuccess(nextPage)
    } catch (error) {
      const message = 'Google Authentication error'
      this.validation.showMessage('social', message)
      Sentry.withScope((scope) => {
        scope.setExtra('Google Native Signin Error', error)
        Sentry.captureException(error)
      })
      this.onError()
    }
  }

  static async tryLogout(): Promise<void> {
    // GoogleAuth plugin does not have a method to check if the
    // user is signed in, so we just try it.
    try {
      await GoogleAuth.signOut()
    } catch (error) {
      // We do not need this in Sentry, just log to console, so
      // it may appear in Sentry data if another error happens
      // after that.
      console.log('Error on native Google logout', error)
    }
  }
}

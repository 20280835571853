import { backend } from './backend'
import { Annotation, HighlightType } from '@/models/interfaces'
import { ContentView } from '@/models/doc'
import { offline } from '@/services/offline'
import { OfflineAnnotations } from './offline_annotations'

/**
 * Backend storage for annotator.
 *
 * Args:
 *   content: the `Content` model to manage higlights for.
 *
 * Returns:
 *   Function returning the storage object for annotator.js.
 *
 * Note: after sending the annotation data to the backend, we also
 * update annotation data in memory.
 *
 * We have the list of annotations in the `content.highlights`, but
 * we do not reload the book content when we navigate between book
 * pages, so, for example, when we create new highlight, we need to
 * both send it to the backend and push to `content.highlights`.
 *
 * This way if we navigate to the next page and then back, we will
 * see the added highlight without page reload.
 *
 * See the related `_createLocally`, `_updateLocally` and `_deleteLocally`
 * methods below.
 */
export function backendStorage(
  content: ContentView,
): () => Record<string, any> {
  const offlineStore = new OfflineAnnotations(content)

  return (): Record<string, any> => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    function trace(action: string, annotation: any): void {
      let copyAnno = annotation
      if (annotation) {
        copyAnno = JSON.parse(JSON.stringify(annotation))
      }
      /* eslint-disable no-console */
      console.debug('annotator.backend.storage.debug: ' + action, copyAnno)
      /* eslint-enable no-console */
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */

    return {
      create: async function (
        annotation: Annotation,
        highlightType: HighlightType = 'highlight',
      ): Promise<Annotation> {
        const contentDescriptor = content.asSerialisable()
        let result
        annotation.highlight_type = highlightType
        if (offline.isOnline) {
          try {
            result = await backend.createHighlight(
              contentDescriptor,
              annotation,
            )
          } catch (e: any) {
            if (e.code === 'ERR_NETWORK' || e.code === 'ECONNABORTED') {
              result = offlineStore.create(contentDescriptor, annotation)
            } else {
              throw e
            }
          }
        } else {
          result = offlineStore.create(contentDescriptor, annotation)
        }
        this._createLocally(result)
        return result
      },

      shareFacebook: async function (
        annotation: Annotation,
      ): Promise<Annotation> {
        return this.share(annotation)
      },

      shareTwitter: async function (
        annotation: Annotation,
      ): Promise<Annotation> {
        return this.share(annotation)
      },

      shareLink: async function (annotation: Annotation): Promise<Annotation> {
        return this.share(annotation)
      },

      share: async function (annotation: Annotation): Promise<Annotation> {
        let result
        if (annotation.id) {
          // Share annotation if it exists already.
          result = await backend.shareHighlight(content, annotation)
          this._updateLocally(result)
        } else {
          // Create new public annotation.
          annotation.is_public = true
          annotation.highlight_type = 'highlight'
          result = await backend.createHighlight(content, annotation)
          this._createLocally(result)
        }
        return result
      },

      update: async function (annotation: Annotation): Promise<Annotation> {
        const contentDescriptor = content.asSerialisable()
        let result
        if (offline.isOnline) {
          try {
            result = await backend.updateHighlight(
              contentDescriptor,
              annotation,
            )
          } catch (e: any) {
            if (e.code === 'ERR_NETWORK' || e.code === 'ECONNABORTED') {
              result = offlineStore.update(contentDescriptor, annotation)
            } else {
              throw e
            }
          }
        } else {
          result = offlineStore.update(contentDescriptor, annotation)
        }
        this._updateLocally(result)
        return result
      },

      delete: async function (annotation: Annotation): Promise<Annotation> {
        const contentDescriptor = content.asSerialisable()
        let result
        if (offline.isOnline) {
          try {
            result = await backend.deleteHighlight(
              contentDescriptor,
              annotation,
            )
          } catch (e: any) {
            if (e.code === 'ERR_NETWORK' || e.code === 'ECONNABORTED') {
              result = offlineStore.delete(contentDescriptor, annotation)
            } else {
              throw e
            }
          }
        } else {
          result = offlineStore.delete(contentDescriptor, annotation)
        }
        this._deleteLocally(result)
        return result
      },

      /* eslint-disable @typescript-eslint/no-unused-vars */
      query: function (queryObj: any): Record<string, unknown> {
        return {
          results: content.highlights,
          meta: { total: content.highlights.length },
        }
        // Unused, do we need this?:
        // for (const index in highlights) {
        //   const highlight = highlights[index]
        //   const annotation = highlight.annotation
        //   // if (queryObj) {
        //   //   if (storage[id].text.includes(queryObj)) {
        //   //     // @ts-ignore
        //   //     result.results.push(storage[id])
        //   //   }
        //   // } else {
        //   //   // @ts-ignore
        //   //   result.results.push(storage[id])
        //   // }
        // }
        // return {
        //   results: content.highlights,
        //   meta: { total: content.highlights.length },
        // }
        // // // return {results: [], meta: {total: 0}};
      },
      /* eslint-enable @typescript-eslint/no-unused-vars */

      configure: function (registry: any): void {
        registry.registerUtility(this, 'storage')
      },

      _createLocally(annotation: Annotation): void {
        content.highlights.push(annotation)
      },

      _updateLocally(annotation: Annotation): void {
        content.highlights = content.highlights.map((value: Annotation) => {
          if (value.id === annotation.id) {
            return annotation
          }
          return value
        })
      },

      _deleteLocally(annotation: Annotation): void {
        content.highlights = content.highlights.filter((value: Annotation) => {
          return value.id !== annotation.id
        })
      },
    }
  }
}

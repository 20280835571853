
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { Book } from '@/models/interfaces'
import { BooksView } from '@/models/book'

import BookCardThumbnail from '@/app/components/book/card/BookCardThumbnail.vue'

@Options({
  components: { BookCardThumbnail },
})
export default class HighlightsCard extends Vue {
  @Prop() private view!: BooksView
  @Prop() private book!: Book

  getNotesWord(notes: number): string {
    return notes === 1 ? `Note` : `Notes`
  }

  getHighlightsWord(highlights: number): string {
    return highlights === 1 ? `Highlight` : `Highlights`
  }

  getBookmarksWord(bookmarks: number): string {
    return bookmarks === 1 ? `Bookmark` : `Bookmarks`
  }
}

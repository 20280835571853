import { backend } from '@/services/backend'
import { DocGroupListItem } from './interfaces'

export class PodcastsSearchView {
  podcasts: DocGroupListItem[] = []

  _term: string = ''
  _limit: number = 4

  _abortController: AbortController | null = null

  async init(searchTerm: string = ''): Promise<void> {
    this._term = searchTerm
    await this._loadData()
  }

  public async updateSearchTerm(term: string): Promise<void> {
    if (term === this._term) {
      return
    }
    this._term = term
    await this._loadData()
  }

  async _loadData(): Promise<void> {
    if (this._term) {
      this.podcasts = await backend.getPodcastSearch(this._term, this._limit)
      return
    }

    // Abort the previous request before sending a new one.
    this._abortController?.abort()

    // Create a new AbortController so that we can cancel
    // this request if a new query is made before it's fulfilled.
    this._abortController = new AbortController()
    const signal = this._abortController.signal

    const response = await backend.getPodcasts(signal)
    this.podcasts = response.slice(0, this._limit)
  }

  get viewAllText(): string {
    return 'View all'
  }

  get viewAllLink(): string {
    return this._term
      ? `/app/podcasts/search/${encodeURI(this._term)}`
      : '/app/podcasts'
  }

  get title(): string {
    return this._term ? 'Podcasts' : 'Popular Podcasts'
  }

  podcastSubtitle(podcast: DocGroupListItem): string {
    const count = podcast.doc_count
    return count + (count === 1 ? ' episode' : ' episodes')
  }

  /**
   * Link for the podcast details (listing) page.
   */
  public link(podcast: DocGroupListItem): Record<string, unknown> {
    return {
      name: 'podcastPage',
      params: {
        url_slug: podcast.url_slug,
      },
    }
  }
}

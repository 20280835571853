<template>
  <div class="free-trial-button">
    <router-link :to="{ name: 'billing' }" class="free-trial-button__link btns">
      Free Trial
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.free-trial-button {
  &__link {
    padding: 9px 22px 7px 22px;
    @media only screen and (max-width: $w-max-content-width) {
      padding: 9px 16px 7px 16px;
      font-size: 13px;
      line-height: 13px;
    }
  }
}
</style>

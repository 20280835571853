/* eslint-disable @typescript-eslint/no-this-alias */
'use strict'

import { Widget } from './widget'
import { addEventHandler, removeEventHandler } from './util'

// Adder shows and hides an annotation adder button that can be clicked on to
// create an annotation.
export const Adder = Widget.extend({
  constructor: function (options: any) {
    Widget.call(this, options)

    this.ignoreMouseup = false
    this.annotation = null
    this.position = null

    this.onCreate = this.options.onCreate
    this.onCreateNote = this.options.onCreateNote
    this.onShare = this.options.onShare
    this.onCopy = this.options.onCopy
    this.onBookmark = this.options.onBookmark

    this.document = this.element.ownerDocument

    const self = this

    addEventHandler(
      this.element,
      'click',
      (e, target) => {
        self._onClick(e, target)
      },
      'button',
    )
    addEventHandler(
      this.element,
      'mousedown',
      (e) => {
        self._onMousedown(e)
      },
      'button',
    )
    addEventHandler(this.document.body, 'mouseup', (e) => {
      self._onMouseup(e)
    })
  },

  destroy: function () {
    removeEventHandler(this.element, 'click')
    removeEventHandler(this.element, 'mousedown')
    removeEventHandler(this.document.body, 'mouseup')

    Widget.prototype.destroy.call(this)
  },

  // Public: Load an annotation and show the adder.
  //
  // annotation - An annotation Object to load.
  // position - An Object specifying the position in which to show the editor
  //
  // If the user clicks on the adder with an annotation loaded, the onCreate
  // handler will be called. In this way, the adder can serve as an
  // intermediary step between making a selection and creating an annotation.
  //
  // Returns nothing.
  load: function (annotation: any, position: any) {
    this.annotation = annotation
    this.position = position
    this.show(position)
  },

  // Public: Show the adder.
  //
  // position - An Object specifying the position in which to show the editor
  //
  // Examples
  //
  //   adder.show()
  //   adder.hide()
  //   adder.show({top: 100, bottom: 80, middle: 50})
  //
  // Returns nothing.
  show: function (position: any) {
    Widget.prototype.show.call(this, position)
  },

  // Event callback: called when the mouse button is depressed on the adder.
  //
  // event - A mousedown Event object
  //
  // Returns nothing.
  _onMousedown: function (event: MouseEvent) {
    // Do nothing for right-clicks, middle-clicks, etc.
    if (event.which > 1) {
      return
    }

    event.preventDefault()
    // Prevent the selection code from firing when the mouse button is
    // released
    this.ignoreMouseup = true
  },

  // Event callback: called when the mouse button is released
  //
  // event - A mouseup Event object
  //
  // Returns nothing.
  _onMouseup: function (event: MouseEvent) {
    // Do nothing for right-clicks, middle-clicks, etc.
    if (event.which > 1) {
      return
    }

    // Prevent the selection code from firing when the ignoreMouseup flag is
    // set
    if (this.ignoreMouseup) {
      event.stopImmediatePropagation()
    }
  },

  // Event callback: called when the adder is clicked. The click event is used
  // as well as the mousedown so that we get the :active state on the adder
  // when clicked.
  //
  // event - A mousedown Event object
  //
  // Returns nothing.
  _onClick: function (event: MouseEvent, target: HTMLElement) {
    // Do nothing for right-clicks, middle-clicks, etc.
    if (event.which > 1) {
      return
    }

    event.preventDefault()

    // Hide the adder
    this.hide()
    this.ignoreMouseup = false

    // Create a new annotation
    if (this.annotation !== null && typeof this.onCreate === 'function') {
      const classes = target.classList

      if (classes.contains('add-highlight')) {
        return this.onCreate(this.annotation, event)
      }
      if (classes.contains('ikindle-copy')) {
        return this.onCopy(this.annotation)
      }
      if (classes.contains('ifacebook-1')) {
        return this.onShare('facebook', this.annotation, event)
      }
      if (classes.contains('itwitter-1')) {
        return this.onShare('twitter', this.annotation, event)
      }
      if (classes.contains('ishare-1')) {
        return this.onShare('link', this.annotation, event)
      }
      if (classes.contains('iflag')) {
        return this.onBookmark(this.annotation, event)
      }
      return this.onCreateNote(this.annotation, event)
    }
  },
})

Adder.template.classList.add('annotator-adder', 'annotator-hide')
Adder.template.innerHTML = `
  <button class="add-highlight" type="button">
    <i class="iconfont ihighlights"></i>
  </button>
  <button class="add-bookmark iconfont iflag" type="button"></button>
  <button class="add-note iconfont inotes" type="button"></button>
  <button class="share-note iconfont ikindle-copy" type="button"></button>
  <button class="share-note iconfont ifacebook-1" type="button"></button>
  <button class="share-note iconfont itwitter-1" type="button"></button>
  <button class="share-note iconfont ishare-1" type="button"></button>
`

// Configuration options
Adder.options = {
  // Callback, called when the user clicks the adder when an
  // annotation is loaded.
  onCreate: null,
}

import {
  Router,
  createRouter as _createRouter,
  createWebHistory,
  RouteLocationNormalized,
  NavigationGuardNext,
} from 'vue-router'
import routes from './routes'

import { updateMetaTags } from '@/helpers/router'
import { auth } from '@/services/auth'
import { platform } from '@/services/platform'
import { maybeSyncAnalyticsIds } from '@/analytics/logic'
import { Mixpanel } from '@/analytics/mixpanel'
import { appPositionTrackingService } from '@/services/app.position.tracking'
import { notificationService } from '@/services/notification'
import { backLink } from '@/models/backlink'
import { initWeglotIfNeeded } from '@/services/weglot'

export function createRouter(): Router {
  const router = _createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      savedPosition: any,
    ): any {
      if (savedPosition) {
        return savedPosition
      }

      const app = document.querySelector('#app')
      // We already have a scroll position control for `book/` pages/routes.
      // We scroll to the top of the screen on every other page visit except the `book/` page.
      if (app && to.path !== '/' && !to.path.includes('book/')) {
        app.scrollTo(0, 0)
      }

      return { top: 0, left: 0 }
    },
  })

  router.beforeEach(
    async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      const pageName = (to.name as string) || ''
      const isPublicPage = to.meta.isPublicPage as boolean
      const isSignupPage = pageName === 'signup'
      const isLoginPage = pageName === 'login'

      initWeglotIfNeeded()

      const isNativeIOSApp = await platform.isNativeIOSApp()

      const isUTMEmail = to.query.utm_source && to.query.utm_source === 'email'
      if (!auth.loggedIn() && !isPublicPage) {
        // If we are not logged in and page requires authorization,
        // redirect to the login page if it's not the user's first visit,
        // otherwise redirect to signup.
        // In the native iOS app, redirect to login regarless of being
        // the user's first visit, see #1844.
        if (isNativeIOSApp || auth.previouslyLoggedIn() || isUTMEmail) {
          next({
            name: 'login',
            query: { redirect: to.fullPath },
          })
        } else {
          next({
            name: 'signup',
            query: { redirect: to.fullPath },
          })
        }
      } else if (auth.loggedIn() && (isSignupPage || isLoginPage)) {
        // If we are logged in and try to visit signup page,
        // redirect to home
        next({
          name: 'home',
        })
      } else {
        next() // Make sure to always call next()!
      }
    },
  )

  router.beforeEach(
    async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      updateMetaTags(to, true)
      next()
    },
  )

  /*
   * This functionality is disabled because it adds a possibility
   * of user to stuck inside questionnaire without possibility to
   * to to the application itself
  router.beforeEach(async (to, from, next) => {
    // Redirect to questionnaire if it wasn't finished
    const step = storage.getItem('sf_oq_step') as string
    // If the step is not 'finish' and we are not at questionnaire - redirect
    if (
      step !== 'questionnaire_finish' &&
      !(to.name as string).includes('questionnaire')
    ) {
      next({ name: step })
    } else {
      next()
    }
  })
  */

  // Check and, if needed, sync the analytics IDs to the backend. We do this
  // here, not in App.vue, so that it will run even if the user does not start
  // a new browser tab/session. In particular, after signup, this will sync the
  // analytics ID on the next route accessed.
  router.beforeEach(
    async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      await maybeSyncAnalyticsIds()
      next()
    },
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  router.afterEach((to: RouteLocationNormalized, from, navigationFailure) => {
    if ((window as any)['__PRERENDER_INJECTED']) {
      // Skip mixpanel page tracking during prerendering.
      return
    }

    if (to.name === 'search' && to.query.term && to.query.term !== '') {
      // We track search queries from the Search page beforeRouteLeave() guard.
      return
    }

    // Delay a page view call a bit to wait for window.locaion.href to change
    // to the new URL
    // See
    // - https://github.com/vuejs/vue-router/issues/1197
    // - https://github.com/vuejs/vue-router/pull/2292
    // - https://github.com/vuejs/vue-router/issues/2079
    setTimeout(() => {
      Mixpanel.pageView()
    }, 100)
  })

  router.beforeEach(async (to, from, next) => {
    const restoredPosition = appPositionTrackingService.nextPositionOnRedirect(
      to,
      from,
    )
    if (restoredPosition) {
      next(restoredPosition)
      return
    }
    next()
  })

  router.beforeEach(async (to, from, next) => {
    backLink.value.updateHistory(to, from)
    next()
  })

  // Track URL changes so we can restore app position when the user switches to
  // a different app and this app reloads from scratch.
  router.afterEach((route: RouteLocationNormalized) =>
    appPositionTrackingService.storePositionOnChange(route),
  )

  // Initialize notifications.
  // TODO The initialization request can be slow (1s) at times.
  router.afterEach(async (route: RouteLocationNormalized) => {
    // Initialize firebase when the user is authenticated and not on a public page.
    // An extra check is done to see if initialization can happen on the current page
    if (auth.loggedIn() && !route.meta.isPublicPage) {
      await notificationService.init(router, route.path)
    }
  })

  return router
}

export const router = createRouter()

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "layout__wrapper" }
const _hoisted_2 = { class: "filter-box filter-box--highlights highlights--full-page" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "highlights__header" }
const _hoisted_5 = { class: "highlights__title-box highlights__title-box--hide-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderRoot = _resolveComponent("HeaderRoot")!
  const _component_ExportHighlightsButton = _resolveComponent("ExportHighlightsButton")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_v_wait = _resolveComponent("v-wait")!
  const _component_HighlightsByBook = _resolveComponent("HighlightsByBook")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { class: "layout--topbar highlights-layout" }, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderRoot, { title: "Highlights" }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h1", {
                  class: "highlights__title",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx._unitTestsThrowError4Sentry && _ctx._unitTestsThrowError4Sentry(...args)))
                }, " Highlights, notes and bookmarks ")
              ]),
              _createVNode(_component_ExportHighlightsButton)
            ])
          ])
        ]),
        _createVNode(_component_v_wait, { for: "Loading Books" }, {
          waiting: _withCtx(() => [
            _createVNode(_component_loading_spinner)
          ]),
          _: 1
        }),
        _createVNode(_component_HighlightsByBook)
      ])
    ]),
    _: 1
  }))
}
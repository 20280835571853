import {
  FacebookLogin,
  FacebookLoginResponse,
} from '@capacitor-community/facebook-login'

import { ValidationState } from '@/helpers/form'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'

import { afterAuth } from './logic'
import { SocialAuthView } from './social'
import { OnAuthStart, OnAuthSuccess, OnAuthError } from './models'
import { auth } from '@/services/auth'

/*
 * Handle Facebook authentication in the native app.
 *
 * See https://github.com/capacitor-community/facebook-login
 */
export class FacebookNativeAuthView implements SocialAuthView {
  public validation: ValidationState

  public onStart: OnAuthStart
  public onSuccess: OnAuthSuccess
  public onError: OnAuthError

  isEnabled: boolean = true

  constructor(
    validation: ValidationState,
    onStart: OnAuthStart,
    onSuccess: OnAuthSuccess,
    onError: OnAuthError,
  ) {
    this.validation = validation
    this.onStart = onStart
    this.onSuccess = onSuccess
    this.onError = onError
  }

  public init(): void {}

  /**
   * Login with Facebook.
   */
  async start(): Promise<void> {
    let response: FacebookLoginResponse
    try {
      this.onStart()
      response = await FacebookLogin.login({
        // Example: permissions: ['public_profile,email'],
        permissions: ['email'],
      })

      if (!response) {
        // We see in Sentry that sometimes the `response` is undefined.
        throw new Error(`Facebook authentication: no response.`)
      }

      if (!response.accessToken) {
        throw new Error(`Facebook authentication: no access token.`)
      }

      const model = { facebookToken: response.accessToken.token }
      const authData = await backend.facebookLoginOrRegister(model)
      auth.saveAuth(authData.token, authData.user)

      authData.user.full_name = await backend.getUserFullname()

      const nextPage = await afterAuth(authData)

      // Redirect the user to the app
      return this.onSuccess(nextPage)
    } catch (error: any) {
      Sentry.withScope((scope) => {
        scope.setContext('SF: Facebook Error', {
          response: response,
        })
        Sentry.captureException(error)
      })
      const message = 'Facebook authentication failed.'
      this.validation.showMessage('social', message)
      this.onError()
    }
  }

  static async tryLogout(): Promise<void> {
    try {
      const tokenResponse = await FacebookLogin.getCurrentAccessToken()
      if (tokenResponse && tokenResponse.accessToken) {
        await FacebookLogin.logout()
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}

<template>
  <div class="skeleton-podcast-episode">
    <div class="skeleton-podcast-episode__cover"></div>
    <div class="skeleton-podcast-episode__lower">
      <div class="skeleton-podcast-episode__title">
        <div class="skeleton-podcast-episode__title-line"></div>
        <div class="skeleton-podcast-episode__title-line"></div>
      </div>
      <div class="skeleton-podcast-episode__description">
        <div class="skeleton-podcast-episode__description-line"></div>
        <div class="skeleton-podcast-episode__description-line"></div>
        <div class="skeleton-podcast-episode__description-line"></div>
        <div class="skeleton-podcast-episode__description-line"></div>
        <div class="skeleton-podcast-episode__description-line"></div>
      </div>
      <div class="skeleton-podcast-episode__date"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets.app/css/common/animations.scss';

.skeleton-podcast-episode {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 450px;
  overflow: hidden;
  border-radius: 12px;
  background: $c-white;
  border: 1px solid $c-border;
  @media only screen and (max-width: $w-tablet-s) {
    height: 415px;
  }

  &__cover {
    width: 100%;
    height: 202px;
    animation: skeleton-loading 1s linear infinite alternate;
  }

  &__lower {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
  }

  &__title-line {
    width: 100%;
    height: 24px;
    border-radius: 4px;
    margin-bottom: 8px;
    animation: skeleton-loading 1s linear infinite alternate;
    @media only screen and (max-width: $w-tablet-s) {
      height: 20px;
      margin-bottom: 6px;
    }

    &:last-child {
      width: 50%;
      margin-bottom: 24px;
    }
  }

  &__description {
    flex: 1;
  }

  &__description-line {
    width: 100%;
    height: 12px;
    border-radius: 4px;
    margin-bottom: 4px;
    animation: skeleton-loading 1s linear infinite alternate;
    @media only screen and (max-width: $w-tablet-s) {
      height: 10px;
    }

    &:last-child {
      width: 50%;
    }
  }

  &__date {
    width: 25%;
    height: 24px;
    border-radius: 4px;
    animation: skeleton-loading 1s linear infinite alternate;
  }
}

.night-mode .skeleton-podcast-episode {
  background: $c-dark-1;
  border-color: $c-dark-3;

  &__cover,
  &__title-line,
  &__date,
  &__description-line {
    animation: skeleton-loading-night-mode 1s linear infinite alternate;
  }
}
</style>

import { waitTimer } from './timer'

function getElement(elem: HTMLElement | string): HTMLElement {
  if (typeof elem === 'string') {
    const el = document.querySelector(elem)
    if (!el) {
      throw Error(`Element not found: ${elem}`)
    }
    return el as HTMLElement
  }
  return elem
}

/**
 * Sometimes, we might need to wait until the element is actually created in DOM.
 */
export async function querySelectorWait(
  selector: string,
  timeout: number = 5000,
  step: number = 100,
): Promise<Element | null> {
  let element = document.querySelector(selector)
  while (!element && timeout > 0) {
    await waitTimer(step)
    element = document.querySelector(selector)
    timeout -= step
  }
  return element
}

export function isVisible(el: HTMLElement | string): boolean {
  const elem = getElement(el)
  return elem.getBoundingClientRect().top > 0
}

/*
 * Scroll into elements view.
 *
 * This function scrolls to the given element view, to make it visible.
 * Possible params are described here:
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
 *
 * Parameters:
 * - alignToTop Optional, boolean value:
 *   If true, the top of the element will be aligned to the top of the visible
 *   area of the scrollable ancestor.
 *   Corresponds to scrollIntoViewOptions: {block: "start", inline: "nearest"}.
 *   This is the default value.
 *
 *   If false, the bottom of the element will be aligned to the bottom of the
 *   visible area of the scrollable ancestor.
 *   Corresponds to scrollIntoViewOptions: {block: "end", inline: "nearest"}.
 *
 * - scrollIntoViewOptions Optional, object with the following properties:
 *   behavior Optional
 *      Defines the transition animation. One of auto or smooth.
 *      Defaults to auto.
 *   block Optional
 *      Defines vertical alignment. One of start, center, end, or nearest.
 *      Defaults to start.
 *   inline Optional
 *      Defines horizontal alignment. One of start, center, end, or nearest.
 *      Defaults to nearest.
 */
export function scrollIntoView(
  el: HTMLElement | string,
  params: Record<string, any> | boolean = {},
): void {
  const elem = getElement(el)
  elem.scrollIntoView(params)
}

/*
 * Scroll to element's top position.
 *
 * This function scrolls some element to its top position. If we have some kind
 * of text element and want it to be shown from the very beginning, this function
 * will scroll it to the top.
 */
export function scrollElement(el: HTMLElement | string): void {
  const elem = getElement(el)
  elem.scrollTo(0, 0)
}

export function insertScript(
  scriptId: string,
  scriptSrc: string,
  scriptDataset: Record<string, unknown>,
): void {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = scriptSrc
  script.id = scriptId
  for (const [key, value] of Object.entries(scriptDataset)) {
    script.dataset[key] = value as any
  }
  script.async = true
  document.head.appendChild(script)
}

import { backend } from '@/services/backend'
import {
  QuestionnaireSection,
  QuestionnairePage,
  QuestionnaireUserChoices,
  QuestionnaireChoice,
} from './long.onboarding.types'
import { Sentry } from '@/services/sentry'
import { storage } from '@/helpers/storage'
import { Mixpanel } from '@/analytics/mixpanel'
import { longOnboarding15024ExperimentVariant } from '@/services/ab'

export class LongQuestionnaireView {
  sections: QuestionnaireSection[]

  private _currentSectionIndex = 0
  private _currentPageIndex = 0
  private _currentSubpageIndex = 0

  private _userChoices: QuestionnaireUserChoices = {}
  private _currentChoice?: QuestionnaireChoice

  private _nextOnboardingPage: () => void

  isContinueButtonVisible = true

  /**
   * @param sections The pages data.
   * @param router The parent page's Vue Router instance.
   * @param userName The user's given name. This value will be used to replace all instances of '{{name}}'
   *   in the pages' `variableTitle` property.
   */
  constructor(
    sections: QuestionnaireSection[],
    nextOnboardingPage: () => void,
    userName?: string,
  ) {
    if (userName) {
      this.sections = this._replaceVariables(sections, userName)
    } else {
      this.sections = sections
    }

    this._userChoices = LongQuestionnaireView.loadUserChoices()

    this.loadPosition()

    if (this.currentSectionIndex === 0 && this.currentPageIndex === 0) {
      this.trackPageView()
      // Save the position for the first page.
      this.savePosition()
    }

    if (this.currentPage.hideContinueButton) {
      this.isContinueButtonVisible = false
    }

    this._nextOnboardingPage = nextOnboardingPage
  }

  /**
   * Replace the `title` property of the given pages with the value of their `variableTitle`,
   * and replace all instaces of '{{name}}' with the given `userName`.
   *
   * @param sections The pages data.
   * @param userName The user's given name. This value will be used to replace all instances of '{{name}}'
   *   in the pages' `variableTitle` property.
   */
  private _replaceVariables(
    sections: QuestionnaireSection[],
    userName: string,
  ): QuestionnaireSection[] {
    return sections.map((section) => {
      const parsedPages = section.pages.map((page) => {
        if (!page.variableTitle) {
          return page
        }
        return {
          ...page,
          title: page.variableTitle.replace(/{{name}}/g, userName),
        }
      })

      return {
        ...section,
        pages: parsedPages,
      }
    })
  }

  get currentChoice(): QuestionnaireChoice | undefined {
    return this._currentChoice
  }

  get userChoices(): QuestionnaireUserChoices {
    return this._userChoices
  }

  get currentSectionIndex(): number {
    return this._currentSectionIndex
  }

  get currentPageIndex(): number {
    return this._currentPageIndex
  }

  get currentSubpageIndex(): number {
    return this._currentSubpageIndex
  }

  /**
   * Get the current page object.
   */
  get currentPage(): QuestionnairePage {
    return this.sections[this.currentSectionIndex].pages[this.currentPageIndex]
  }

  /**
   * Get the number of subpages in the current page.
   *
   * Returns `1` if it doesn't have subpages.
   */
  get currentPageLength(): number {
    return this.getPageLength(this.currentPage)
  }

  /**
   * Return `true` if the current subpage is the last subpage in the current page.
   */
  get isLastSubpage(): boolean {
    return this.currentSubpageIndex === this.currentPageLength - 1
  }

  /**
   * Get the current section object.
   */
  get currentSection(): QuestionnaireSection {
    return this.sections[this.currentSectionIndex]
  }

  /**
   * Get the number of pages in the current section, including subpages.
   */
  get currentSectionLength(): number {
    let count = 0

    const pages = this.currentSection.pages
    pages.map((page) => {
      count += this.getPageLength(page)
    })

    return count
  }

  /**
   * Get the length of the `pages` array of the current section.
   */
  get currentPagesLength(): number {
    return this.currentSection.pages.length
  }

  /**
   * Return `true` if the current section is the last section in the questionnaire.
   */
  get isLastSection(): boolean {
    return this.currentSectionIndex === this.sections.length - 1
  }

  /**
   * Return `true` if the current page is the last page in the current section.
   */
  get isSectionLastPage(): boolean {
    return this.currentPageIndex === this.currentPagesLength - 1
  }

  /**
   * Return `true` if the current page is the last page of the questionnaire.
   */
  get isLastPage(): boolean {
    return this.isLastSection && this.isSectionLastPage
  }

  /**
   * Get the progress towards the end of the current section.
   *
   * @Returns a decimal percentage value [0-1] representing the progress through the section.
   *
   * @example the 1st page in a 10 page section returns `0.1`
   * @example the 5th page in a 10 page section returns `0.5`
   * @example the 10th page in a 10 page section returns `1`
   */
  get currentSectionProgress(): number {
    return (this.sectionPreviousPagesLength + 1) / this.currentSectionLength
  }

  /**
   * Get the number of pages visited in this section, including subpages.
   */
  get sectionPreviousPagesLength(): number {
    let count = this.currentSubpageIndex

    for (let i = 0; i < this.currentPageIndex; i++) {
      const page = this.currentSection.pages[i]
      count += this.getPageLength(page)
    }

    return count
  }

  /**
   * Get the number of subpages in the given page.
   *
   * Returns `1` if it doesn't have subpages.
   */
  getPageLength(page: QuestionnairePage): number {
    return page.content?.length || 1
  }

  /**
   * Save the current Questionnaire position to local storage.
   */
  savePosition(): void {
    storage.setItem(
      'sf_lq_position',
      JSON.stringify({
        section: this.currentSectionIndex,
        page: this.currentPageIndex,
      }),
    )
  }

  /**
   * Update the Questionnaire position from the saved data in local sotrage.
   */
  loadPosition(): void {
    const positionString = storage.getItem('sf_lq_position')
    if (!positionString) {
      return
    }

    const position = JSON.parse(positionString)

    this._currentSectionIndex = position.section
    this._currentPageIndex = position.page
  }

  /**
   * Update `this._userChoices` from the data saved in local storage.
   */
  static loadUserChoices(): QuestionnaireUserChoices {
    const choicesString = storage.getItem('sf_lq_choices')
    if (!choicesString) {
      return {}
    }

    return JSON.parse(choicesString)
  }

  /**
   * Track the current page view and choices to Mixpanel.
   */
  trackPageView(): void {
    Mixpanel.trackOnboardingPageView(
      `questionnaire - ${this.currentPage.id}`,
      this.userChoices,
    )
  }

  /**
   * Navigate to the next page.
   *
   * Returns `false` if the current is the last
   * subpage, and `true` otherwise.
   *
   * It just returns a boolean instead of navigating
   * to the next page because on some pages we might want
   * to handle some extra logic before that.
   */
  nextSubpage(): boolean {
    if (this.isLastSubpage) {
      return false
    }

    this._currentSubpageIndex++
    return true
  }

  /**
   * Navigate to the next page.
   */
  async nextPage(): Promise<void> {
    this.saveUserChoice()

    if (this.isLastPage) {
      // We submit the choices after the signup on variant D
      if (longOnboarding15024ExperimentVariant() !== 'D') {
        await LongQuestionnaireView.submitUserChoices(this.userChoices)
      }
      this._nextOnboardingPage()
      return
    }

    // Reset the current subpage index.
    this._currentSubpageIndex = 0

    if (this.isSectionLastPage) {
      // Go to the first page of the next section.
      this._currentSectionIndex++
      this._currentPageIndex = 0
    } else {
      this._currentPageIndex++
    }

    this.trackPageView()
    this.savePosition()

    if (this.currentPage.hideContinueButton) {
      this.hideContinueButton()
    } else {
      this.showContinueButton()
    }
  }

  /**
   * Set the value of `this._currentChoice`.
   */
  setCurrentChoice(newValue: QuestionnaireChoice | undefined): void {
    this._currentChoice = newValue
  }

  /**
   * Add the current page's choice to `this._userChoices`, and then
   * reset `this.currentPageChoice`.
   */
  saveUserChoice(): void {
    if (this._currentChoice === undefined) {
      return
    }

    // Don't add empty arrays to the choices object.
    if (
      Array.isArray(this._currentChoice) &&
      this._currentChoice.length === 0
    ) {
      this.setCurrentChoice(undefined)
      return
    }

    this._userChoices[this.currentPage.id] = this._currentChoice

    // Make a copy of the user choices to local storage so we can
    // restore it later if they leave the questionnaire page and
    // come back later.
    storage.setItem('sf_lq_choices', JSON.stringify(this.userChoices))

    // Reset `this._currentChoice`.
    this.setCurrentChoice(undefined)
  }

  /**
   * Send `this._userChoices` to the backend.
   */
  static async submitUserChoices(
    userChoices: QuestionnaireUserChoices,
  ): Promise<void> {
    try {
      await backend.saveLongQuestionnaire(userChoices)
    } catch (error: any) {
      Sentry.withScope((scope) => {
        scope.setExtra(
          'message',
          'Long Onboarding Questionnaire aborted on error',
        )
        Sentry.captureException(error)
      })
    }

    // After submitting it to the backend,
    // we don't need this data in local storage anymore.
    storage.removeItem('sf_lq_choices')
    storage.removeItem('sf_lq_position')
  }

  /**
   * Hide the continue button.
   *
   * The continue button is the standard way to navigate through the questionnaire,
   * but some pages have their own logic to handle the navigation; so we can hide it
   * in those cases.
   */
  hideContinueButton(): void {
    this.isContinueButtonVisible = false
  }

  /**
   * Show the continue button.
   */
  showContinueButton(): void {
    this.isContinueButtonVisible = true
  }
}

/**
 * Import the given image from the onboarding images folder.
 */
export function getImage(image: string): string {
  return require(`@/assets.app/images/onboarding/${image}`)
}

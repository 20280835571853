
import { nextTick } from 'vue'
import { Vue } from 'vue-class-component'
import { Options, Watch } from 'vue-property-decorator'

import Layout from '@/app/components/Layout.vue'
import CategoriesSelection from '@/app/components/listing/categories/CategoriesSelection.vue'
import BooksBlock from '@/app/components/listing/BooksBlock.vue'
import ArticlesBlock from '@/app/components/listing/ArticlesBlock.vue'

import vLoading from 'vue-wait/src/components/v-wait.vue'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'
import LoadingError from '@/components/ui/LoadingError.vue'

import { wait } from '@/helpers/vue-wait'
import { Sentry } from '@/services/sentry'

import { SearchView } from '@/models/search'

import { assertNotNull } from '@/helpers/typing'
import HeaderRoot from '@/app/components/header/Root.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'
import NoSearchResults from '@/app/components/doc/search/NoSearchResults.vue'
import { setFocus } from '@/helpers/form'
import { platform } from '@/services/platform'
import EpisodesBlock from '@/app/components/listing/EpisodesBlock.vue'
import PodcastsBlock from '@/app/components/listing/PodcastsBlock.vue'

import BooksBlockSkeleton from '@/app/components/listing/skeleton-loaders/BooksBlockSkeleton.vue'
import ArticlesBlockSkeleton from '@/app/components/listing/skeleton-loaders/ArticlesBlockSkeleton.vue'
import PodcastsBlockSkeleton from '@/app/components/listing/skeleton-loaders/PodcastsBlockSkeleton.vue'
import PodcastEpisodesBlockSkeleton from '@/app/components/listing/skeleton-loaders/PodcastEpisodesBlockSkeleton.vue'
import TagsBlockSkeleton from '@/app/components/listing/skeleton-loaders/TagsBlockSkeleton.vue'

import EmptyPodcastsBlock from '@/app/components/listing/EmptyPodcastsBlock.vue'

@Options({
  components: {
    EpisodesBlock,
    PodcastEpisodesBlockSkeleton,
    EmptyPodcastsBlock,
    PodcastsBlock,
    PodcastsBlockSkeleton,
    CategoriesSelection,
    TagsBlockSkeleton,
    Layout,
    BooksBlock,
    BooksBlockSkeleton,
    ArticlesBlock,
    ArticlesBlockSkeleton,
    'v-wait': vLoading,
    LoadingSpinner,
    LoadingError,
    HeaderRoot,
    NoSearchResults,
    InputWithContextMenu,
  },
})
export default class Search extends Vue {
  view: SearchView | null = null

  async beforeMount(): Promise<void> {
    wait.start(this, 'Loading Search')

    await this.loadSearch()

    // Autofocus search field on Desktop
    // We don't focus on mobile because soft keyboard takes a lot of space
    if (platform.isDesktopWidth() && this.$refs?.search) {
      nextTick(() => {
        setFocus((this.$refs.search as InputWithContextMenu).$el as HTMLElement)
      })
    }

    // Initialize event listeners in `nextTick`, after all elements are rendered.
    nextTick(() => {
      // We don't have view initialized if loadSearch() failed.
      if (this.view) {
        this.view.initEventListeners()
      }
    })
  }

  @Watch('view.search')
  onSearch(): void {
    return assertNotNull(this.view).searchHandler()
  }

  async loadSearch(): Promise<void> {
    try {
      const searchTerm = this.$route.query.term as string

      this.view = new SearchView(this, searchTerm)

      await this.view.init()

      wait.end(this, 'Loading Search')
    } catch (error: any) {
      wait.start(this, 'Error Loading Search')
      Sentry.captureException(error)
    }
  }

  /**
   * Submit handler.
   *
   * We need to wrap an input into <form> to have a special
   * "Search" version of the soft keyboard on mobile.
   *
   * When user submits the form (clicks "Search" on soft keyboard)
   * we need to hide the keyboard.
   */
  formSubmit(): void {
    ;((this.$refs.search as InputWithContextMenu).$el as HTMLElement).blur()
  }
}

import { ValidationState } from '@/helpers/form'
import { GOOGLE_CLIENT_ID } from '@/init/settings'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import { afterAuth } from './logic'
import { SocialAuthView } from './social'
import { OnAuthStart, OnAuthSuccess, OnAuthError } from './models'
import { jwtDecode } from 'jwt-decode'

// Time to wait until we show the "google library did not load" error.
const GSI_LOAD_WAIT_TIME = 5000

export interface GoogleCredentialResponse {
  clientId: string
  credential: string
}

export enum AuthMode {
  Signup,
  Signin,
}

// This is just the subset of fields which we actually need. The complete
// list of available fields is at https://developers.google.com/identity/gsi/web/guides/handle-credential-responses-js-functions#handle_credential_response
interface GoogleJWTPayload {
  email: string
  given_name: string
  family_name: string
}

/*
 * Handle Google authentication API response.
 * Service to initialize google's GSI library and make it render it's signin
 * or login button to the designated mountpoint in a Vue component.
 *
 * Note that this is only for web. For mobile, see google.native.ts instead.
 */
export class GoogleAuthView implements SocialAuthView {
  public validation: ValidationState

  public onStart: OnAuthStart
  public onSuccess: OnAuthSuccess
  public onError: OnAuthError

  // Used to display Signin vs Signup. We don't have full control, as
  // when GSI renders the button, it also takes into account whether the
  // user is already logged into google when deciding what button text to
  // render.But we can make suggestions, which it sometimes pays attention to.
  private authMode: AuthMode = AuthMode.Signin

  // Unused. required by the `SocialAuthView` interface
  isEnabled: boolean = false

  // Set true once `GoogleSignInButton` has mounted. That component renders
  // the container div, which in turn `renderButtons` in this file will
  // pass to GSI as the container into which it should render the actual
  // signin button(s). So we need to know if/when the container is ready.
  hasContainerComponentMounted: boolean = false

  // Set true once we have initialised the google library and rendered the
  // signin with google button. Used to prevent it from happening twice
  hasSetup: boolean = false

  signupTextExperiment = 'default'

  constructor(
    validation: ValidationState,
    onStart: OnAuthStart,
    onSuccess: OnAuthSuccess,
    onError: OnAuthError,
  ) {
    this.validation = validation
    this.onStart = onStart
    this.onSuccess = onSuccess
    this.onError = onError
    ;(window as any)._Hack_For_Testing_Google = this
  }

  public init(): void {
    // Show the loading error after 5 seconds if we have not yet succesfully
    // set up the signin button
    setTimeout(() => {
      if (!this.hasSetup && !this.validation.hasError('social')) {
        this.validation.showError('social', 'Google library did not load.')
        this.onError()
      }
    }, GSI_LOAD_WAIT_TIME)

    // Hook for googles gsi javascript bundle loading because we
    // cannot initialise and render the signin button until it has.
    // This is triggered in public/index.app.html via
    // `crossBrowserEvent('google-loaded');` on the <script>'s `onLoad` event.
    window.addEventListener('google-loaded', () => {
      // A global is used here for _hasGSILoaded so that:
      //   1) it survives vue router page changes in the same way that the
      //       script tag itself does (otherwise, when logging out for example,
      //       there is no page reload, so their is no google-loaded event,
      //       but this GoogleAuthView is recreated, so the state is lost).
      //   2) it's easier to mock in tests
      window._hasGSILoaded = true
      this._doSetup()
    })
  }

  // This is called by `GoogleSignInButton` when it has mounted. That
  // component renders the container div, which we will then ask GSI
  // to render the actual buttons into (see `renderButtons`). We cannot
  // do that until the container div exists, so this callback lets us know.
  containerComponentHasMounted = async (authMode: AuthMode): Promise<void> => {
    this.authMode = authMode
    this.hasContainerComponentMounted = true
    this._doSetup()
  }

  // Google's javascript bundle invokes this on a "signin moment" (their
  // terminology for a succesful login). The signin moment may occur after
  // selecting a user and entering password, etc. from the google signing popup,
  // but if the user is already logged in to google, and previously granted us
  // permission, it may happen directly after clicking the "signin with google"
  // button, without showing the popup.
  //
  // There is no corrosponding error handler; we simply never hear back from
  // google if the "signin moment" does not occur, regardless of whether it's
  // because the user never clicked the signin button, clicked in and canceled,
  // or some other failure occured, there is no means for us to find out.
  _handleGoogleAuthToken = async (
    args: GoogleCredentialResponse,
  ): Promise<void> => {
    try {
      this.onStart()
      // `credential` is a jwt token. We decode it here to get the email
      // stored inside (other fields such as user name are also available)
      // However, to properly verify both the token itself and its contents,
      // we also pass the entire thing to the back end, which calls
      // `google.oauth.id_token.verify_oauth2_token` on it. This same
      // python function also works for older style pre-gsi (gapi, oauth2)
      // based google logins, so no backend changes where needed when migrating
      // to GSI
      const credential = args.credential
      const decoded: GoogleJWTPayload = jwtDecode(credential)
      const email = decoded.email
      const model = { googleToken: credential, email: email }
      const authData = await backend.googleLoginOrRegister(model)

      authData.user.full_name = decoded.given_name

      // Redirect the user to the app
      const nextPage = await afterAuth(authData)
      return this.onSuccess(nextPage)
    } catch (error) {
      this._errorHandler(error)
    }
  }

  /*
   * Initialize Google API and attach Google Sign-In button click handler.
   *
   * Based on Google API documentation:
   * https://developers.google.com/identity/gsi/web/guides/overview
   */
  _doSetup(): void {
    if (!(window._hasGSILoaded && this.hasContainerComponentMounted)) {
      // Environment not ready, cannot setup yet. We are waiting for
      // both the google library to be loaded, and our container component
      // to have mounted so that we have somewhere to draw into
      // both the "has gsi loaded" and "has container mounted" events will
      // try to call `_doSetup` after setting their respective "ready" flags,
      // but those two callbacks can happen in any order, so are waiting here
      // for whichever of them is the slower, before trying to actually
      // to the setup code.
      return
    }
    if (this.hasSetup) {
      // Have already setup, don't try to repeat it.
      return
    }
    const config = {
      client_id: GOOGLE_CLIENT_ID,
      callback: this._handleGoogleAuthToken,
    }
    this._googleInitialize(config)
    this.validation.reset()
    this.renderButtons()
    this.hasSetup = true
  }

  renderButtons(): void {
    // Google insists on rendering its own button which are non-responsive;
    // it does not let us provide our own graphics. Consequently we render two
    // buttons, big and small, and use css break points to show the correct one
    // on mobile/ not mobile screen widths

    // Note that the 'text' is only a suggestion, which GSI pays attention to
    // amongst other factors such as whether google thinks the user has
    // already logged in in the past. So we can ask for "sign up" but we
    // might sometimes get "sign in" anyway.
    const text =
      this.authMode === AuthMode.Signin ? 'signin_with' : 'signup_with'
    const bigContainerId = 'sign-in-with-google-container-big'
    const bigContainer = document.getElementById(bigContainerId)
    const bigRenderOptions = {
      theme: 'outline',
      size: 'large',
      shape: 'pill',
      width: 240,
      text: text,
    }
    if (bigContainer) {
      this._googleRenderButton(bigContainer, bigRenderOptions)
    }

    const smallContainerId = 'sign-in-with-google-container-small'
    const smallRenderOptions = {
      theme: 'outline',
      type: 'icon',
      width: 40,
      size: 'large',
      shape: 'pill',
    }

    const smallContainer = document.getElementById(smallContainerId)

    if (smallContainer) {
      this._googleRenderButton(smallContainer, smallRenderOptions)
    }
  }

  _googleRenderButton(container: HTMLElement, options: any): void {
    try {
      ;(window as any).google.accounts.id.renderButton(container, options)
    } catch (error) {
      Sentry.captureException(error)
      // Re-throw error to let google library know that
      // something went wrong.
      throw error
    }
  }

  _googleInitialize(config: any): void {
    try {
      ;(window as any).google.accounts.id.initialize(config)
    } catch (error) {
      Sentry.captureException(error)
      throw error
    }
  }

  /**
   * Gooogle Sign In error handler.
   *
   * Most errors are swallowed silently by GSI, so this is unlikely to
   * fire in ordinary user-flows, but rather it indicates a hard crash.
   */
  _errorHandler(error: any): any {
    const message =
      'Google authentication error occurred. Please try again later.'
    this.onError()

    this.validation.showMessage('social', message)
    Sentry.withScope((scope) => {
      scope.setExtra('Google Error', error)
      Sentry.captureMessage('Google Sign-In error')
    })
  }
}

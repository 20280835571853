import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EOCButton = _resolveComponent("EOCButton")!
  const _component_Annotator = _resolveComponent("Annotator")!

  return (_openBlock(), _createBlock(_component_Annotator, {
    oncopy: "return false",
    content: _ctx.content
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "sf-chapter",
        innerHTML: _ctx.chapterHtml
      }, null, 8, _hoisted_1),
      (_ctx.isChapterEOC())
        ? (_openBlock(), _createBlock(_component_EOCButton, {
            key: 0,
            text: "Subscribe to Read Full Summary",
            "ios-text": "Full Subscription Required to Read More"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["content"]))
}

import { createApp, h, type App } from 'vue'
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { RouterLink } from 'vue-router'

import Annotator from '@/app/components/doc/reading/Annotator.vue'
import EOCButton from '@/app/components/billing/EOCButton.vue'
import AdditionalInformation from '@/app/components/doc/reading/AdditionalInformation.vue'

import { ContentView } from '@/models/doc'
import { parseTextForTranslation } from '@/services/weglot'

@Options({
  components: {
    Annotator,
    EOCButton,
    AdditionalInformation,
  },
})
export default class BookChapter extends Vue {
  @Prop() private content!: ContentView

  private internalApps: App<Element>[] = []

  chapterHtml = this.getHtml()

  updated(): void {
    this.chapterHtml = this.getHtml()
    this.$nextTick(() => {
      this.replaceLinks()
    })
  }

  getHtml(): string {
    return `<h2>${this.content.title}</h2>
      ${parseTextForTranslation(this.content.text)}
      ${this.getAdditionalMaterialsHtml}`
  }

  mounted(): void {
    this.replaceLinks()
  }

  beforeUnmount(): void {
    this.internalApps.forEach((app) => app.unmount())
  }

  replaceLinks(): void {
    this.internalApps.forEach((app) => app.unmount())

    const replaceAnchor = (anchor: HTMLElement): void => {
      const url = anchor.getAttribute('href')

      if (!url) {
        return
      }

      // Skip external links
      if (
        !url.startsWith('https://www.shortform.com/app') &&
        !url.startsWith('https://dev.shortform.com/app') &&
        !url.startsWith('https://shortform.com/app') &&
        !url.startsWith('/app')
      ) {
        return
      }

      const path = url
        .replace('https://www.shortform.com', '')
        .replace('https://dev.shortform.com', '')
        .replace('https://shortform.com', '')

      // Create a new Vue app to render the RouterLink.
      // eslint-disable-next-line vue/one-component-per-file
      const linkApp = createApp({
        render() {
          return h(RouterLink, {
            to: path,
          })
        },
      })
      // Important: we need to attach the main app router to
      // the dynamic router link to have it working properly.
      linkApp.use(this.$router)

      // Create the document fragment to mount the RouterLink.
      const el = document.createDocumentFragment()
      const html = anchor.innerHTML
      // Save all anchor attributes, we need css classes and data-xxx attribtes.
      const cssClasses = anchor.className
      const dataAttributes: { [key: string]: string } = {}
      for (let i = 0; i < anchor.attributes.length; i++) {
        const attr = anchor.attributes[i]
        if (attr.name.startsWith('data-')) {
          dataAttributes[attr.name] = attr.value
        }
      }

      // Mounting vue app to the fragment element works, but
      // type information expects string | Element, so we cast it.
      const mountedApp = linkApp.mount(el as any as Element)
      mountedApp.$el.innerHTML = html
      mountedApp.$el.className = cssClasses
      for (const key in dataAttributes) {
        mountedApp.$el.setAttribute(key, dataAttributes[key])
      }

      // Replace the original anchor with the mounted component.
      anchor.replaceWith(el)

      this.internalApps.push(linkApp)
    }

    const anchors = this.$el.getElementsByTagName('a')
    Array.from(anchors).forEach((a) => replaceAnchor(a as HTMLElement))
  }

  isChapterEOC(): boolean {
    return this.content.isChapterEOC
  }

  /**
   * Returns the HTML for the additional materials section.
   *
   * We want annotator work on the additional materials section,
   * and, at the same time, we need to have one root element for the
   * annotator (it is `.highlights-content > div.sf-chapter`, see
   * @/app/components/doc/reading/Annotator.vue).
   *
   * So, we create a temporary Vue app, mount it to a div, and then
   * return the outerHTML of the mounted app's root element.
   * That HTML is then inserted into the div with chapter HTML.
   *
   * See also: #11402.
   */
  get getAdditionalMaterialsHtml(): string {
    const commentaries = this.content.commentaries

    if (!commentaries || this.content.isChapterEOC) {
      return ''
    }

    // eslint-disable-next-line vue/one-component-per-file
    const tempApp = createApp({
      render() {
        return h(AdditionalInformation, {
          commentaries: commentaries,
        })
      },
    })

    const el = document.createElement('div')
    const mountedApp = tempApp.mount(el)
    const mountedAppHtml = mountedApp.$el.outerHTML

    // Remove the temporary app from the DOM.
    el.remove()
    tempApp.unmount()

    return `
      <div class="sf-chapter__additional-information">
        ${mountedAppHtml}
      </div>
    `
  }
}


import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import DocLayout from '@/app/components/doc/Layout.vue'
import PrevNext from '@/app/components/doc/PrevNext.vue'
import Chapter from '@/app/components/doc/reading/Chapter.vue'
import Exercise from '@/app/components/doc/reading/Exercise.vue'
import ExerciseFree from '@/app/components/doc/reading/ExerciseFree.vue'
import ExerciseRedesign from '@/app/components/doc/reading/ExerciseRedesign.vue'
import EarlyAccessBannerBook from '@/app/components/doc/EarlyAccessBannerBook.vue'
import EarlyAccessBannerPodcast from '@/app/components/doc/EarlyAccessBannerPodcast.vue'
import vLoading from 'vue-wait/src/components/v-wait.vue'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'

import AudioPlayer from '@/app/components/doc/player/Player.vue'
import AudioPlayerDisabled from '@/app/components/doc/player/PlayerDisabled.vue'

import { wait } from '@/helpers/vue-wait'
import { assertNotNull } from '@/helpers/typing'
import { emitter } from '@/services/mitt'
import { DocView } from '@/models/doc'
import { AnnotationWithContent } from '@/models/interfaces'
import { backend } from '@/services/backend'
import { RouteLocationNormalized } from 'vue-router'
import { Sentry } from '@/services/sentry'
import {
  aiLabel13537ExperimentVariant,
  redesignedExercisePage13941Experiment,
} from '@/services/ab'

@Options({
  components: {
    DocLayout,
    PrevNext,
    Chapter,
    Exercise,
    ExerciseFree,
    ExerciseRedesign,
    AudioPlayer,
    AudioPlayerDisabled,
    EarlyAccessBannerPodcast,
    EarlyAccessBannerBook,
    'v-wait': vLoading,
    LoadingSpinner,
  },
})
export default class BookContent extends Vue {
  @Prop() private doc!: DocView

  showRedesignedPage = false

  async beforeMount(): Promise<void> {
    await this.getExercisePageExperimentVariant()
    await this.handleHighlight(this.$route)
  }

  async beforeUpdate(): Promise<void> {
    await this.getExercisePageExperimentVariant()
  }

  async beforeRouteUpdate(to: RouteLocationNormalized): Promise<void> {
    await this.handleHighlight(to)
  }

  async getExercisePageExperimentVariant(): Promise<void> {
    if (this.doc.isCurrentPageOpenQuestions) {
      wait.start(this, 'Exercise Page Experiment')
      this.showRedesignedPage = await redesignedExercisePage13941Experiment()
      wait.end(this, 'Exercise Page Experiment')
    }
  }

  get headerStyle(): Record<string, string> {
    if (assertNotNull(this.doc).audio) {
      return {
        'padding-bottom': '0px',
      }
    }
    return {}
  }

  onDocContentClick(event: any): void {
    emitter.emit('docContentClicked', event)
  }

  /**
   * Fetch a highlight by ID if the `highlight` query param is present.
   */
  async _maybeFetchHighlight(
    route: RouteLocationNormalized,
  ): Promise<AnnotationWithContent | undefined> {
    const highlightId = route.query?.highlight as string | undefined
    if (!highlightId) {
      return undefined
    }
    try {
      return await backend.getHighlight(highlightId)
    } catch (e) {
      Sentry.captureException(e)
      return undefined
    }
  }

  get showBookBanner(): boolean {
    return this.doc.doc_type === 'book' && aiLabel13537ExperimentVariant()
  }

  async handleHighlight(route: RouteLocationNormalized): Promise<void> {
    const highlight = await this._maybeFetchHighlight(route)
    if (highlight) {
      this.doc.scrollToHighlight(highlight)
    }
  }
}

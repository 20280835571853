<template>
  <div class="skeleton-book">
    <div class="skeleton-book__upper">
      <div class="skeleton-book__cover"></div>
    </div>
    <div class="skeleton-book__lower">
      <div class="skeleton-book__tag"></div>
      <div class="skeleton-book__title"></div>
      <div class="skeleton-book__author"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets.app/css/common/animations.scss';

.skeleton-book {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 368px;
  overflow: hidden;
  border-radius: 12px;
  background: $c-white;
  border: 1px solid $c-border;
  @media only screen and (max-width: $w-tablet-s) {
    height: 297px;
  }

  &__upper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 197px;
    background-color: $c-bg;
    @media only screen and (max-width: $w-tablet-s) {
      height: 153px;
    }
  }

  &__cover {
    width: 110px;
    height: 147px;
    border-radius: 8px;
    animation: skeleton-loading 1s linear infinite alternate;
    @media only screen and (max-width: $w-tablet-s) {
      width: 85px;
      height: 114px;
    }
  }

  &__lower {
    flex: 1;
    padding: 16px 19px 19px;
  }

  &__tag {
    width: 50%;
    height: 15px;
    border-radius: 4px;
    margin-bottom: 26px;
    animation: skeleton-loading 1s linear infinite alternate;
    @media only screen and (max-width: $w-tablet-s) {
      height: 12px;
    }
  }

  &__title {
    width: 100%;
    height: 24px;
    border-radius: 4px;
    margin-bottom: 8px;
    animation: skeleton-loading 1s linear infinite alternate;
    @media only screen and (max-width: $w-tablet-s) {
      height: 20px;
    }
  }

  &__author {
    width: 75%;
    height: 20px;
    border-radius: 4px;
    animation: skeleton-loading 1s linear infinite alternate;
    @media only screen and (max-width: $w-tablet-s) {
      height: 16px;
    }
  }
}

.night-mode .skeleton-book {
  background: $c-dark-1;
  border-color: $c-dark-3;

  &__upper {
    background-color: $c-dark-2;
  }

  &__cover,
  &__tag,
  &__title,
  &__author {
    animation: skeleton-loading-night-mode 1s linear infinite alternate;
  }
}
</style>

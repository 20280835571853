
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import ArticleLayout from '@/app/components/article/Layout.vue'

import AudioPlayer from '@/app/components/doc/player/Player.vue'
import AudioPlayerDisabled from '@/app/components/doc/player/PlayerDisabled.vue'
import Annotator from '@/app/components/doc/reading/Annotator.vue'
import EOCButton from '@/app/components/billing/EOCButton.vue'

import { ArticleView } from '@/models/article'
import { emitter } from '@/services/mitt'
import { formatDate } from '@/helpers/time'
import { parseTextForTranslation } from '@/services/weglot'

@Options({
  components: {
    ArticleLayout,
    AudioPlayer,
    AudioPlayerDisabled,
    Annotator,
    EOCButton,
  },
})
export default class ArticleContent extends Vue {
  @Prop() private article!: ArticleView

  publishDate(): string {
    if (this.article.original_publish_date) {
      return formatDate(this.article.original_publish_date)
    }
    return ''
  }

  /**
   * We use the `getHtml` function instead of writing this directly since
   * 1) we want to use the v-html directive to render article.page.text
   * 2) the title, author, and blurb need to be annotatable, which means
   *    they need to be children of the `.sf-chapter` div.
   * See issue #4109.
   */
  getHtml(): string {
    // If the blurb exists, we add H3 after it, which renders the horizontal line.
    const blurbHtml =
      `<p>${this.article.blurb}</p>` + (this.article.blurb ? '<h3></h3>' : '')

    return `<div>
        <h2 class="article-single__title">${this.article.title}</h2>
        <p>
          <span class="article-single__author text-gray">
            By ${this.article.author}
            <span class="line"></span>
            <a
              href="${this.article.original_url}"
              target="_blank"
              class="article-single__publisher-link"
            >
              Published ${this.publishDate()} in
              <em>${this.article.publisher.name}</em></a
            >
            ${this.getSubscribeLink()}
          </span>
        </p>
        ${blurbHtml}
     </div>
     ${parseTextForTranslation(this.article.page.text)}`
  }

  private getSubscribeLink(): string {
    if (!this.article.publisher.subscribe_url) {
      return ''
    }
    return `
      <span class="line"></span>
      <a
        href="${this.article.publisher.subscribe_url}"
        target="_blank"
      >Subscribe</a>`
  }

  onDocContentClick(event: any): void {
    emitter.emit('docContentClicked', event)
  }
}

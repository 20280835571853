
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'
import { emitter } from '@/services/mitt'
import { platform } from '@/services/platform'
import { isIOS, isSafari } from '@/helpers/userAgent'
import { getProperLanguage, weglotInitialized } from '@/services/weglot'

@Options({})
export default class App extends Vue {
  isNativeApp: boolean = false
  isSafari = isSafari() || isIOS()
  currentLang = 'en'

  async beforeMount(): Promise<void> {
    this.isNativeApp = await platform.isNativeApp()

    if (weglotInitialized()) {
      this.listenForLanguageChanges()
    } else {
      window.addEventListener('weglot_initialized', () => {
        this.listenForLanguageChanges()
      })
    }
  }

  /**
   * Change the value of `currentLang` whenever
   * the current language changes.
   */
  listenForLanguageChanges(): void {
    const weglot = (window as any).Weglot

    this.currentLang = getProperLanguage(weglot.getCurrentLang())

    weglot.on('languageChanged', (newLang: string) => {
      this.currentLang = newLang
    })
  }

  resizeHeight(): void {
    // On mobile browsers, the address bar can reduce the viewport
    // height without reducing the vh unit.
    // So if we set the height to 100vh, the bottom may be obscured by
    // an address bar or navbar in the app.
    //
    // To work around this, we set the CSS variable --vh to 1/100
    // of the height of the actual viewport, and then set this
    // instead of 100vh:
    //   height: calc(var(--vh, 1vh) * 100);
    // More: https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
    const windowsVH = window.innerHeight / 100
    const elem = this.$el as HTMLElement
    if (elem) {
      elem.style.setProperty('--vh', windowsVH + 'px')
    }
  }

  mounted(): void {
    this.resizeHeight()
    window.addEventListener('resize', () => {
      this.resizeHeight()
      this.emitResizeEvent()
    })

    window.addEventListener('orientationchange', () => {
      this.resizeHeight()
      this.emitResizeEvent()
    })
  }

  emitResizeEvent(): void {
    emitter.emit('windowResize')
  }

  onScroll(): void {
    emitter.emit('appScroll', this.$refs.scroll as HTMLElement)
  }
}

import { ComponentPublicInstance } from 'vue'
import {
  ArticleListItem,
  DocListItem,
  DocType,
  Article,
  Publisher,
  ThreadForDoc,
  ApiResultDocs,
} from './interfaces'
import { DocView, DocsView, DocSortOption, ContentView } from './doc'
import { ListingPage } from './docs.listing'
import { backend } from '@/services/backend'

export class ArticleView extends DocView implements Article {
  public publisher: Publisher
  public thread: ThreadForDoc
  public is_read: boolean
  public next_url_slug: string | null
  public next_title: string | null
  public cover_thumbnail: string

  constructor(vm: ComponentPublicInstance, article: Article) {
    super(vm, article)
    this.publisher = article.publisher
    this.thread = article.thread
    this.is_read = article.is_read
    this.next_url_slug = article.next_url_slug
    this.next_title = article.next_title
    this.cover_thumbnail = article.cover_thumbnail
  }

  get page(): ContentView {
    return this._pages[0]
  }

  get nextLink(): Record<string, unknown> | null {
    if (this.next_url_slug) {
      return {
        title: this.next_title,
        params: {
          name: 'article',
          params: {
            url_slug: this.next_url_slug,
          },
        },
      }
    }
    return null
  }

  get publisherLinkParams(): Record<string, unknown> | null {
    return {
      name: 'articles',
      query: {
        search: this.publisher.name,
      },
    }
  }

  isCurrentThread(): boolean {
    return false
  }
}

export class ArticlesView extends DocsView {
  constructor(
    docs: DocListItem[],
    tags: string[],
    listingPage?: ListingPage,
    showCtaBlock: boolean = true,
    defaultSort: DocSortOption = DocSortOption.first_published_at,
  ) {
    // Sort option, 'created' is server-side default for articles
    super(docs, tags, defaultSort, listingPage)
    this._showCtaBlock = showCtaBlock
  }

  public docTypeName(): DocType {
    return 'article'
  }

  static async createView(
    articlesResponse: ApiResultDocs,
    listingPage?: ListingPage,
    showCtaBlock: boolean = true,
    defaultSort: DocSortOption = DocSortOption.first_published_at,
  ): Promise<ArticlesView> {
    const view = new ArticlesView(
      articlesResponse.data,
      [],
      listingPage,
      showCtaBlock,
      defaultSort,
    )
    await view.init()
    return view
  }

  static async createSearchView(
    searchTerm: string = '',
  ): Promise<ArticlesView> {
    let articles: DocListItem[]

    if (searchTerm) {
      articles = await DocsView.searchDocsLimited('article', searchTerm)
    } else {
      const response = await backend.getArticles()
      articles = response.data
    }

    return this.createView({ data: articles })
  }

  async _updateFilteredDocs(): Promise<void> {
    // Override parent method to inject CTA block for free users.
    await super._updateFilteredDocs()

    if (this.needInjectCtaBlocks()) {
      /* For example, for the desktop screen width, there are 3 articles in a row:
       * | A | A | A |
       * | A | A | A |
       * We inject the first CTA block after 2 rows of articles (6 articles)
       * and the CTA block takes the space of two articles:
       * | A | A | A |
       * | A | A | A |
       * |  CTA  | A |
       * The gap between two CTA blocks is 10 articles (1 article + 3 rows)
       * We inject more CTA blocks after 3 more rows of articles:
       * | A | A | A |
       * | A | A | A |
       * |  CTA  | A |
       * | ... 3 rows of articles ... |
       * |  CTA  | A |
       *
       * The same CTA blocks positions are preserved on tablet/mobile screen
       * widths, meaning that CTA block is still on 7, 18, 29, ... positions.
       */
      super.injectCtaBlocks(6, 10)
    }
  }

  /**
   * Link for the article page.
   */
  public link(article: Article): Record<string, unknown> {
    return {
      name: 'article',
      params: {
        url_slug: article.url_slug,
      },
    }
  }

  /**
   *  Url for cover image to show on list page.
   */
  getItemCoverUrl(article: ArticleListItem): string {
    return article.cover_thumbnail
  }
}

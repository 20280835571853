/* package annotator.ui */
'use strict'

import { gettext as _t, getHighlightCoords, getSelectionCoords } from './util'
import { Adder } from './adder'
import { Editor } from './editor'
import { Highlighter } from './highlighter'
import { TextSelector } from './textselector'
import { Viewer } from './viewer'

import { MAX_HIGHLIGHT_TEXT_LENGTH } from '@/init/settings'
import { trackCopyButtonClickReadingPage } from '@/analytics/mixpanel/mixpanel'
import { Annotation } from '@/models/interfaces'

// The trim strips whitespace from either end of a string.
//
// This usually exists in native code, but not in IE8.
function trim(s: any) {
  if (typeof String.prototype.trim === 'function') {
    return String.prototype.trim.call(s)
  } else {
    return s.replace(/^[\s\xA0]+|[\s\xA0]+$/g, '')
  }
}

// The annotationFactory returns a function that can be used to construct an
// annotation from a list of selected ranges.
function annotationFactory(contextEl: any, ignoreSelector: any) {
  return function (ranges: any) {
    const text = [],
      serializedRanges = []

    for (let i = 0, len = ranges.length; i < len; i++) {
      const r = ranges[i]
      text.push(trim(r.text()))
      serializedRanges.push(r.serialize(contextEl, ignoreSelector))
    }

    return {
      quote: text.join(' / '),
      ranges: serializedRanges,
    }
  }
}

// Helper function to add permissions checkboxes to the editor
function addPermissionsCheckboxes(editor: any, ident: any, authz: any) {
  function createLoadCallback(action: any) {
    return function loadCallback(field: any, annotation: any) {
      field.style.display = ''

      const u = ident.who()
      const input = field.querySelector('input')

      // Do not show field if no user is set
      if (typeof u === 'undefined' || u === null) {
        field.style.display = 'none'
      }

      // Do not show field if current user is not admin.
      if (!authz.permits('admin', annotation, u)) {
        field.style.display = 'none'
      }

      // See if we can authorise without a user.
      if (authz.permits(action, annotation, null)) {
        input.checked = true
      } else {
        input.checked = false
      }
    }
  }

  function createSubmitCallback(action: any) {
    return function submitCallback(field: any, annotation: any) {
      const u = ident.who()

      // Don't do anything if no user is set
      if (typeof u === 'undefined' || u === null) {
        return
      }

      if (!annotation.permissions) {
        annotation.permissions = {}
      }

      if (field.querySelector('input').checked) {
        delete annotation.permissions[action]
      } else {
        // While the permissions model allows for more complex entries
        // than this, our UI presents a checkbox, so we can only
        // interpret "prevent others from viewing" as meaning "allow
        // only me to view". This may want changing in the future.
        annotation.permissions[action] = [authz.authorizedUserId(u)]
      }
    }
  }

  editor.addField({
    type: 'checkbox',
    label: _t('Allow anyone to <strong>view</strong> this annotation'),
    load: createLoadCallback('read'),
    submit: createSubmitCallback('read'),
  })

  editor.addField({
    type: 'checkbox',
    label: _t('Allow anyone to <strong>edit</strong> this annotation'),
    load: createLoadCallback('update'),
    submit: createSubmitCallback('update'),
  })
}

/**
 * function:: main([options])
 *
 * A module that provides a default user interface for Annotator that allows
 * users to create annotations by selecting text within (a part of) the
 * document.
 *
 * Example::
 *
 *     app.include(annotator.ui.main);
 *
 * :param Object options:
 *
 *   .. attribute:: options.element
 *
 *      A DOM element to which event listeners are bound. Defaults to
 *      ``document.body``, allowing annotation of the whole document.
 *
 *   .. attribute:: options.editorExtensions
 *
 *      An array of editor extensions. See the
 *      :class:`~annotator.ui.editor.Editor` documentation for details of editor
 *      extensions.
 *
 *   .. attribute:: options.viewerExtensions
 *
 *      An array of viewer extensions. See the
 *      :class:`~annotator.ui.viewer.Viewer` documentation for details of viewer
 *      extensions.
 *
 */
export function main(options: any): any {
  if (typeof options === 'undefined' || options === null) {
    options = {}
  }

  // @ts-ignore
  options.element = options.element || global.document.body
  options.editorExtensions = options.editorExtensions || []
  options.viewerExtensions = options.viewerExtensions || []

  // Local helpers
  const makeAnnotation = annotationFactory(options.element, '.annotator-hl')

  // Object to hold local state
  const s: any = {
    interactionPoint: null,
  }

  function start(app: any) {
    const ident = app.registry.getUtility('identityPolicy')
    const authz = app.registry.getUtility('authorizationPolicy')

    function validateAnnotationLength(ann: any) {
      const annotationLength = ann.quote.length
      if (annotationLength > MAX_HIGHLIGHT_TEXT_LENGTH) {
        const errorMessage = `Sorry, your selection is longer than ${MAX_HIGHLIGHT_TEXT_LENGTH} characters, so it cannot be saved.`
        alert(errorMessage)
        return false
      }
      return true
    }

    async function copyText(ann: Annotation) {
      if (!validateAnnotationLength(ann)) {
        return
      }

      // If the experiment is enabled, we want to allow copy text on the reading page.
      // We need to limit the length of copied text to 2000 characters.
      const selectedText = ann.quote.substring(0, 2000)
      // We need to check if the window is secure context, because
      // the clipboard API is not available in insecure contexts.
      if (window.isSecureContext) {
        trackCopyButtonClickReadingPage()
        await navigator.clipboard.writeText(selectedText)
      }
    }

    s.adder = new Adder({
      onCreate: function (ann: any, event: Event) {
        if (!ann.text) {
          ann.text = ''
        }
        if (!validateAnnotationLength(ann)) {
          return
        }
        app.annotations
          .create(ann, 'highlight', event)
          .catch(function (error: any) {
            if (error !== 'editing canceled') {
              alert('Sorry, I could not create that highlight')
              throw error
            }
          })
      },
      onBookmark: function (ann: any, event: Event) {
        if (!ann.text) {
          ann.text = ''
        }
        if (!validateAnnotationLength(ann)) {
          return
        }
        app.annotations
          .create(ann, 'bookmark', event)
          .catch(function (error: any) {
            if (error !== 'editing canceled') {
              alert('Sorry, I could not create that bookmark')
              throw error
            }
          })
      },
      onShare: function (where: string, ann: any, event: Event) {
        if (!ann.text) {
          ann.text = ''
        }
        app.annotations.share(where, ann, event).catch(function (error: any) {
          if (error !== 'editing canceled') {
            /* eslint-disable no-console */
            console.log(error)
            /* eslint-enable no-console */
            throw error
          }
        })
      },
      onCopy: async function (ann: any) {
        await copyText(ann)
      },
      onCreateNote: function (ann: any, event: Event) {
        if (!validateAnnotationLength(ann)) {
          return
        }
        s.highlighter.draw(ann)
        return s.editor
          .load(ann, s.interactionPoint)
          .then(() => {
            // Undraw the highlight so that it doesn't appear as a duplicate
            // after the following create().
            s.highlighter.undraw(ann)
            app.annotations.create(ann, 'highlight', event)
          })
          .catch(function (error: any) {
            s.highlighter.undraw(ann)
            if (error !== 'editing cancelled') {
              /* eslint-disable no-console */
              console.error(error)
              /* eslint-enable no-console */
              throw error
            }
          })
      },
    })
    s.adder.attach()

    s.editor = new Editor({
      extensions: options.editorExtensions,
    })
    s.editor.attach()

    addPermissionsCheckboxes(s.editor, ident, authz)

    s.highlighter = new Highlighter(options.element, {})

    s.textselector = new TextSelector(options.element, {
      onSelectionStarted: options.onSelectionStarted,
      onSelectionInProgress: function () {
        // We hide the annotator menu when the user is in the process
        // of making a selection because the annotator menu interfers when a user
        // is making a selection.
        s.adder.hide()
      },
      onSelection: function (
        ranges: any /* , event: MouseEvent | null = null */,
      ) {
        if (ranges.length > 0) {
          const annotation = makeAnnotation(ranges)
          s.interactionPoint = getSelectionCoords(s.adder.options.appendTo)
          s.adder.load(annotation, s.interactionPoint)
        } else {
          s.adder.hide()
        }
      },
    })

    s.viewer = new Viewer({
      onCreate: function (ann: any, event: Event) {
        ann.highlight_type = 'highlight'
        // Set flag to skip showing editor
        ann._skipEditor = true
        // Directly update the annotation without showing the editor
        app.annotations
          .update(ann, 'highlight', event)
          .catch(function (error: any) {
            if (error !== 'editing canceled') {
              alert('Sorry, I could not create that highlight')
              throw error
            }
          })
      },
      onEdit: function (ann: any) {
        // Copy the interaction point from the shown viewer:
        // s.interactionPoint = util.$(s.viewer.element).css(['top', 'left'])
        s.interactionPoint = s.viewer.position

        app.annotations.update(ann).catch(function (error: any) {
          if (error !== 'editing canceled') {
            /* eslint-disable no-console */
            console.log(error)
            /* eslint-enable no-console */
            throw error
          }
        })
      },
      onCopy: async function (ann: any) {
        await copyText(ann)
      },
      onShare: function (where: string, ann: any, event: Event) {
        if (!ann.text) {
          ann.text = ''
        }
        app.annotations.share(where, ann, event).catch(function (error: any) {
          if (error !== 'editing canceled') {
            /* eslint-disable no-console */
            console.log(error)
            /* eslint-enable no-console */
            throw error
          }
        })
      },
      onDelete: function (ann: any) {
        // If we have a note, ask the user if it was actually an intention.
        // In the viewer, the 'x' that deletes the note, might be interpreted
        // as a close button for the viewer window, so it is better to make sure.
        if (!ann.text || window.confirm('Delete the highlight and note?')) {
          app.annotations['delete'](ann).catch(function (error: any) {
            if (error !== 'editing canceled') {
              /* eslint-disable no-console */
              console.log(error)
              /* eslint-enable no-console */
              throw error
            }
          })
        }
      },

      onBookmark: function (ann: any, event: Event) {
        ann.highlight_type = 'bookmark'
        // Set flag to skip showing editor
        ann._skipEditor = true
        // Directly update the annotation without showing the editor
        app.annotations
          .update(ann, 'bookmark', event)
          .catch(function (error: any) {
            if (error !== 'editing canceled') {
              alert('Sorry, I could not create that bookmark')
              throw error
            }
          })
      },
      permitEdit: function (ann: any) {
        return authz.permits('update', ann, ident.who())
      },
      permitDelete: function (ann: any) {
        return authz.permits('delete', ann, ident.who())
      },
      autoViewHighlights: options.element,
      extensions: options.viewerExtensions,
    })
    s.viewer.attach()
  }

  return {
    start: start,

    destroy: function () {
      s.adder.destroy()
      s.editor.destroy()
      s.highlighter.destroy()
      s.textselector.destroy()
      s.viewer.destroy()
    },

    annotationsLoaded: function (anns: any) {
      s.highlighter.drawAll(anns)
    },
    annotationCreated: function (ann: any) {
      s.highlighter.draw(ann)
      // We loose the mouseUp event somewhere and viewer is not shown
      // anymore after we create new higlight, this and few similar
      // statements below fix this.
      // Although, it would be good to find and fix the root cause,
      // it can be reproduced like this:
      // - Select the text
      // - Add the highlight (or share)
      // - Mouse over any other highlight - viewer is not shown
      s.viewer.mouseDown = false

      // Some parts of the selection are not cleared after the annotation
      // and highlight process [#8894] - so we programmatically clear
      // the selection after the annotation is completed
      s.textselector.clearSelection()
    },
    annotationDeleted: function (ann: any) {
      s.highlighter.undraw(ann)
      s.viewer.mouseDown = false
    },
    annotationSharedFacebook: function (ann: any) {
      if (ann.id) {
        s.highlighter.redraw(ann)
      } else {
        s.highlighter.draw(ann)
      }
      s.viewer.mouseDown = false
    },
    annotationSharedTwitter: function (ann: any) {
      if (ann.id) {
        s.highlighter.redraw(ann)
      } else {
        s.highlighter.draw(ann)
      }
      s.viewer.mouseDown = false
    },
    annotationSharedLink: function (ann: any) {
      if (ann.id) {
        s.highlighter.redraw(ann)
      } else {
        s.highlighter.draw(ann)
      }
      s.viewer.mouseDown = false
    },
    annotationUpdated: function (ann: any) {
      s.highlighter.redraw(ann)
    },

    beforeAnnotationUpdated: function (annotation: any) {
      // Skip showing editor for bookmark type changes
      if (annotation._skipEditor) {
        return Promise.resolve()
      }

      const highlights = annotation._local.highlights as HTMLElement[]
      const container = s.editor.options.appendTo

      return s.editor.load(
        annotation,
        getHighlightCoords(highlights, container),
      )
    },
  }
}

import { TRUSTPILOT_KEY } from '@/init/settings'
import { backend } from '@/services/backend'
import { auth } from '@/services/auth'
import { Sentry } from '@/services/sentry'
import { Mixpanel } from '@/analytics/mixpanel'

import {
  DocListItem,
  DocType,
  ApiResultCollectionWithBooks,
  CollectionListItem,
  BookOfTheDayItem,
  ApiResult,
  ApiResultDocs,
} from './interfaces'
import {
  DocSortOption,
  DocView,
  DocsView,
  ChapterView,
  ContentView,
} from './doc'
import { platform } from '@/services/platform'
import { promptForRating } from '@/services/cordova.apprate'

import {
  Content,
  OpenQuestionsContent,
  OpenQuestion,
  OpenAnswer,
  Exercise,
  Book,
  BookListItem,
  Thread,
  WorkoutThread,
} from './interfaces'
import { BookTutorial, BookTutorialStep } from './doc.tutorial'
import { ListingPage } from './docs.listing'
import { aiLabel13537ExperimentVariant } from '@/services/ab'

/**
 * User answer to the open quesion
 */
export class OpenAnswerView implements OpenAnswer {
  id?: string = undefined
  open_question_id: string
  answer?: string = undefined

  private _question: OpenQuestion

  constructor(question: OpenQuestion, answer?: OpenAnswer) {
    this._question = question
    this.open_question_id = question.id
    if (answer) {
      this.validateAnswerBelongsToQuestion(answer, this._question)
      this.id = answer.id
      this.answer = answer.answer
    }
  }

  private validateAnswerBelongsToQuestion(
    answer: OpenAnswer,
    question: OpenQuestion,
  ): void {
    if (answer.open_question_id !== question.id) {
      throw new Error(
        'Wrong exercise answer data, open_question_id: ' +
          answer.open_question_id +
          ' parent question id: ' +
          question.id,
      )
    }
  }

  get questionText(): string {
    return this._question.question
  }

  get questionId(): string {
    return this._question.id
  }
}

/**
 * User work on OpenQuestions content.
 *
 * This class wires together questions and user answers,
 * this model is used in the BookExercise view to
 * display both questions and answers.
 */
export class ExerciseView implements Exercise {
  id?: string = undefined
  content_id: string
  submitted_at?: string | null = null

  answers: OpenAnswerView[] = []
  threads?: WorkoutThread[] = []
  submitted?: boolean = undefined

  private _parentContent: OpenQuestionsView

  constructor(parentContent: OpenQuestionsView, exercise?: Exercise) {
    this._parentContent = parentContent
    this.content_id = this._parentContent.id

    if (exercise) {
      this._updateExerciseData(exercise)
    } else {
      this._updateAnswersData({})
    }
  }

  public get title(): string {
    return this._parentContent.title
  }

  public get text(): string {
    return this._parentContent.text
  }

  public get book(): BookView {
    return this._parentContent.book
  }

  public async save(): Promise<Exercise> {
    const exercise = await backend.saveExercise(
      this._parentContent,
      this.getExerciseData(),
    )
    this.id = exercise.id
    return exercise
  }

  public async submit(): Promise<Exercise> {
    try {
      const exercise = await backend.saveExercise(
        this._parentContent,
        this.getExerciseData(true),
      )
      this.submitted_at = exercise.submitted_at
      this.id = exercise.id
      if (exercise.submitted_at) {
        this._parentContent.completed = exercise.submitted_at
      }
      return exercise
    } catch (error: any) {
      if (error.request && error.request.status === 400) {
        this.submitted_at = null
      } else {
        Sentry.captureException(error)
      }
      throw error
    }
  }

  public async discuss(): Promise<Thread> {
    if (!this.id) {
      throw new Error('Can not discuss the exercise, id is not set')
    }
    return await backend.discussExercise(this._parentContent, this)
  }

  /**
   * Returns true if the "end-of-content" version of the exercise should be rendered.
   */
  public get isExerciseEOC(): boolean {
    if (this.book.is_free) {
      // No end-of-content if the book is free.
      return false
    }
    // If plan is free - render end-of-content, otherwise - not.
    return auth.isFreePlan()
  }

  private _updateExerciseData(exercise: Exercise): void {
    this.validateExerciseBelongsToContent(exercise, this._parentContent)
    this.id = exercise.id
    this.content_id = exercise.content_id
    this.submitted_at = exercise.submitted_at
    this.threads = exercise.threads

    // Put answers into question id -> answer map.
    const answersMap: { [key: string]: OpenAnswer } = {}
    for (const idx in exercise.answers) {
      const answer = exercise.answers[idx]
      answersMap[answer.open_question_id] = answer
    }
    this._updateAnswersData(answersMap)
  }

  private _updateAnswersData(answersMap: { [key: string]: OpenAnswer }): void {
    this.answers = []
    // Go over questions and create appropriate list of related answers.
    for (const idx in this._parentContent.questions) {
      const question = this._parentContent.questions[idx]
      if (question.id in answersMap) {
        this.answers.push(new OpenAnswerView(question, answersMap[question.id]))
      } else {
        this.answers.push(new OpenAnswerView(question))
      }
    }
  }

  private validateExerciseBelongsToContent(
    exercise: Exercise,
    parentContent: OpenQuestionsContent,
  ): void {
    if (exercise.content_id !== parentContent.id) {
      throw new Error(
        'Wrong exercise content, content_id: ' +
          exercise.content_id +
          ' parent content: ' +
          parentContent.id,
      )
    }
  }

  private getExerciseData(submitted: boolean = false): Exercise {
    const answers: OpenAnswer[] = []
    const exerciseData = {
      id: this.id,
      content_id: this.content_id,
      answers: answers,
      submitted: submitted,
    }
    for (const idx in this.answers) {
      const answer = this.answers[idx]
      if (answer.id || answer.answer) {
        const answerData = {
          open_question_id: answer.open_question_id,
          answer: answer.answer,
        }
        exerciseData.answers.push(answerData)
      }
    }
    return exerciseData
  }
}

/**
 * OpenQuestions content.
 */
export class OpenQuestionsView
  extends ChapterView
  implements OpenQuestionsContent
{
  public questions: OpenQuestion[]
  public workouts: Exercise[]

  private _book: BookView

  /**
   * Current exercise - user's work on the OpenQuestions content.
   *
   * We can have many exericse instances, but only one worked on right now.
   */
  currentExercise: ExerciseView

  constructor(book: BookView, content: OpenQuestionsContent) {
    super(book, content)
    this._book = book
    this.questions = content.questions
    this.workouts = content.workouts
    if (this.workouts.length > 0) {
      // For now we assume we only have one exercise per content,
      // later we will support multiple exerice instances (where the
      // user is able to go through the same set of questions more
      // than once).
      this.currentExercise = new ExerciseView(
        this,
        this.workouts[this.workouts.length - 1],
      )
    } else {
      this.currentExercise = new ExerciseView(this)
    }
  }

  /**
   * Returns CSS class for page icon in the sidebar.
   */
  get iconCssClass(): string {
    return 'fas fa-pen text-orange'
  }

  get book(): BookView {
    return this._book
  }
}

/**
 * Checklist data and logic to display content in components.
 */
export class ChecklistView extends ChapterView implements Content {
  /**
   * Returns CSS class for page icon in the sidebar.
   */
  get iconCssClass(): string {
    return 'ni ni-bullet-list-67 text-orange'
  }
}

/**
 * Quiz data and logic to display content in components.
 */
export class QuizView extends ChapterView implements Content {
  /**
   * Returns CSS class for page icon in the sidebar.
   */
  get iconCssClass(): string {
    return 'fas fa-pen text-orange'
  }
}

export class BookView extends DocView implements Book {
  createTutorial(): BookTutorial {
    return new BookTutorial(this)
  }

  get aboutTitle(): string {
    return 'About Book'
  }

  get showDownload(): boolean {
    return true
  }

  /**
   * Return the full summary item text.
   */
  public get fullSummaryItemText(): string {
    return 'Full Book Guide'
  }

  routeNames(): Record<string, string> {
    return {
      community: 'book.community',
      thread: 'book.thread',
      edit_thread: 'book.edit_thread',
      highlights: 'book.highlights',
      export: 'book.export',
      page: 'book.page',
      preview: 'book.preview',
    }
  }

  /**
   * Force the sidebar to stay open, regardless of scrolling or clicking.
   * @returns true if sidebar must remain open
   *
   * This is a part of scroll and click handling logic used by DocControls.
   * We don't hide the sidebar if the TOC or tutorial are shown,
   * but these components exist only for Book object.
   */
  keepSidebarAndHeaderOpen(): boolean {
    // Do not hide at top or bottom of the page.
    const keepOpenByScrollPos = super.keepSidebarAndHeaderOpen()
    if (keepOpenByScrollPos) {
      return keepOpenByScrollPos
    }

    // Do not hide if tutorial is active.
    if (
      this.tutorial &&
      this.tutorial.isActive() &&
      // We do not show the last ("Finish") step on mobile, so the last step is "Night"
      this.tutorial.step <= BookTutorialStep.night
    ) {
      return true
    }

    // Do not hide the sidebar if toc is open.
    if (this.toc && this.toc.isVisible) {
      return true
    }

    return false
  }

  /**
   * Factory function to create chapter Record<string, unknown>
   * based on content_type value.
   */
  protected createChapter(content: Content): ContentView {
    if (content.content_type === 'chapter') {
      return new ChapterView(this, content)
    }
    if (content.content_type === 'open_questions') {
      return new OpenQuestionsView(this, content as OpenQuestionsContent)
    }
    if (content.content_type === 'quiz') {
      return new QuizView(this, content)
    }
    if (content.content_type === 'checklist') {
      return new ChecklistView(this, content)
    }
    // We've got this error in Sentry, log `content` to the console to
    // add the details if it happens again.
    console.log(content)
    throw new Error('Unknown content type: ' + content.content_type)
  }

  /**
   * Get start/continue reading button variant
   */
  startReadingButton(): any {
    const startedReading = this.progress > 1

    if (auth.isPaidPlan()) {
      return {
        text: startedReading ? 'Continue Reading' : 'Start Reading',
        icon: startedReading ? 'ibook' : 'iadd',
      }
    }
    if (!this.is_free) {
      return {
        text: 'Preview Book',
        icon: 'ibook',
      }
    }
    return {
      text: startedReading ? 'Continue Reading' : 'Read for Free',
      icon: startedReading ? 'ibook' : 'iadd',
    }
  }

  public async doneClick(): Promise<void> {
    Mixpanel.trackBookDoneClick(this)
    // Progress is saved on timer in BookSummaryPage, so we just
    // redirect to Home here.
    // Note: vue-router expects Dictionary<string> in params, the value doesn't
    // matter for us - we just check whether the param is present
    this._vm.$router.push({ name: 'home' })
  }

  get doneText(): string {
    return 'Go to Discover page'
  }

  /**
   * Returns true if the AI content label should be rendered.
   */
  get showAiLabel(): boolean {
    return this.is_ai && aiLabel13537ExperimentVariant()
  }
}

/**
 * Book data and logic to display book in components.
 */
export class BooksView extends DocsView {
  private _showFreeAtTheTop: boolean

  constructor(
    docs: DocListItem[],
    tags: string[],
    defaultSort: DocSortOption = DocSortOption.title,
    showFreeAtTheTop: boolean = false,
    listingPage?: ListingPage,
    showCtaBlock: boolean = true,
  ) {
    super(docs, tags, defaultSort, listingPage)
    // The `true` at the end is `showFreeAtTheTop`
    // Show free books at the top for new free users only
    this._showFreeAtTheTop = showFreeAtTheTop
    this._showCtaBlock = showCtaBlock
  }

  public docTypeName(): DocType {
    return 'book'
  }

  static async createSearchView(
    tags: string[],
    searchTerm: string = '',
  ): Promise<BooksView> {
    let books: DocListItem[]
    let docSort: DocSortOption

    if (searchTerm) {
      books = await DocsView.searchDocsLimited('book', searchTerm)
      docSort = DocSortOption.none
    } else {
      // If there's no search term, return the most popular books.
      const response = await backend.getBooks()
      books = response.data
      docSort = DocSortOption.popular
    }

    return this.createView(
      { data: books },
      docSort,
      undefined,
      false,
      undefined,
      tags,
    )
  }

  static async createView(
    booksResponse: ApiResultDocs,
    defaultSort?: DocSortOption,
    listingPage?: ListingPage,
    showCtaBlock: boolean = true,
    showFreeAtTheTop?: boolean,
    tags: string[] = [],
  ): Promise<BooksView> {
    // Different sort order for new and old users
    const sortOrder: DocSortOption =
      defaultSort ??
      (auth.isNewUser()
        ? DocSortOption.popular
        : DocSortOption.first_published_at)

    if (showFreeAtTheTop === undefined) {
      showFreeAtTheTop = auth.isFreePlan() && auth.isNewUser()
    }

    const books = booksResponse.data

    const collection: CollectionListItem | undefined = (
      booksResponse as ApiResultCollectionWithBooks
    ).collection
    if (collection) {
      listingPage?.initFromCollection(collection)
    }

    const view = new BooksView(
      books,
      tags,
      sortOrder,
      showFreeAtTheTop,
      listingPage,
      showCtaBlock,
    )
    await view.init()
    return view
  }

  // Show free books at the top.
  protected showFreeAtTheTop(): boolean {
    return this._showFreeAtTheTop
  }

  get books(): BookListItem[] {
    return this.docs
  }

  get filteredBooks(): BookListItem[] {
    return this.filteredDocs
  }

  async _updateFilteredDocs(): Promise<void> {
    if (this.searchTerm === '') {
      // When the search query is empty, display the most popular books.
      this.setSort(DocSortOption.popular)
    } else if (this.searchTerm !== null) {
      // When there is a search query, use the same sorting as the backend response.
      this.setSort(DocSortOption.none)
    }

    // Override parent method to inject CTA block for free users
    await super._updateFilteredDocs()

    if (this.needInjectCtaBlocks()) {
      /* For example, for the desktop screen width, there are 4 books in a row:
       * | B | B | B | B |
       * | B | B | B | B |
       * We inject the first CTA block the first row of books (4 books)
       * and the CTA block takes the space of two books:
       * | B | B | B | B |
       * | B | B | B | B |
       * |  CTA  | B | B |
       * The gap between two CTA blocks is 18 books (2 books + 4 rows).
       * We inject more CTA blocks after 4 more rows of books:
       * | B | B | B | B |
       * | B | B | B | B |
       * |  CTA  | B | B |
       * | ... 4 rows of books ... |
       * |  CTA  | B | B |
       *
       * The same CTA blocks positions are preserved on tablet/mobile screen
       * widths, meaning that CTA block is still on 5, 24, 43, ... positions.
       */
      super.injectCtaBlocks(4, 18)
    }
  }

  bookListingButton(book: BookListItem): any {
    if (auth.isPaidPlan()) {
      return {
        class: 'btn-outline-primary btns--white',
        text: 'Read Now',
        colClass: ['col-3 pr-0', 'col-6 px-0', 'col-3 pl-0'],
      }
    }
    if (!book.is_free) {
      return {
        class: 'btn-outline-primary btns--white',
        text: 'Preview Book',
        colClass: ['col-3 pr-0', 'col-6 px-0', 'col-3 pl-0'],
      }
    }
    return {
      class: 'btn-primary',
      text: 'Read for Free',
      colClass: ['col-3 pr-0', 'col-6 px-0', 'col-3 pl-0'],
    }
  }

  /**
   * Start link for the book, according to the tracked reading position.
   */
  startLinkParams(book: BookListItem): Record<string, unknown> {
    if (!this.continueReading || !book.position_chapter) {
      return {
        name: 'book.preview',
        params: {
          url_slug: book.url_slug,
        },
      }
    }
    return {
      name: 'book.page',
      params: {
        url_slug: book.url_slug,
        page_url_slug: book.position_chapter,
      },
    }
  }
}

export async function showReviewPrompt(): Promise<void> {
  const isNativeApp = await platform.isNativeApp()
  if (!isNativeApp) {
    return
  }

  const deviceInfo = await platform.deviceInfo()
  const resp = await backend.getReviewInfo(
    deviceInfo.platform,
    deviceInfo.appVersion,
  )

  // We don't need to show the prompt.
  if (!resp.review) {
    return
  }

  const result = await promptForRating()
  if (result === true) {
    await backend.saveReviewInfo(deviceInfo.platform, deviceInfo.appVersion)
  }
}

export async function trustpilotReviewPrompt(): Promise<void> {
  const trustpilotId = await backend.getTrustpilotId()
  if (trustpilotId) {
    // Add Trustpilot library to <head>, initialize trustpilot
    // Copied as-is from Trustpilot docs
    const w = <any>window
    w.TrustpilotObject = 'tp'
    w['tp'] =
      w['tp'] ||
      function (...args: any): void {
        ;(w['tp'].q = w['tp'].q || []).push(...args)
      }
    // Prevent adding tag again if it already exists from previous calls in the session
    const tpScriptNode = document
      .getElementsByTagName('script')
      .namedItem('trustpilot')
    if (!tpScriptNode) {
      const tpScript = document.createElement('script')
      tpScript.setAttribute('src', 'https://invitejs.trustpilot.com/tp.min.js')
      tpScript.setAttribute('name', 'trustpilot')
      tpScript.onload = onTruepilotLoad(w, trustpilotId)
      document.head.appendChild(tpScript)
    }
  }
}

export function onTruepilotLoad(w: any, trustpilotId: string): () => any {
  return function (): void {
    w.tp('register', TRUSTPILOT_KEY)
    const trustpilot_invitation = {
      recipientEmail: auth.getEmail(),
      referenceId: trustpilotId,
      source: 'InvitationScript',
    }
    w.tp('createInvitation', trustpilot_invitation)
    // Since we can't track when the invitation call is completed, we add a 2s delay.
    setTimeout(function () {
      backend.setTrustpilotSubmitted()
    }, 2000)
  }
}

export class BookOfTheDayView {
  private _description: string | undefined
  private _book: BookListItem

  constructor(description: string | undefined, book: BookListItem) {
    this._description = description
    this._book = book
  }

  static async createView(
    result: ApiResult<BookOfTheDayItem | undefined>,
  ): Promise<BookOfTheDayView | null> {
    const data = result?.data

    const book = data?.doc

    if (!book) {
      return null
    }

    return new BookOfTheDayView(data?.description, book)
  }

  /*
   * Returns the description of the Book of the Day object
   * or if not present the blurb of the book
   * The blurb of the book will be shortened on desktop to 185 chars and to 190/250 on mobile desktop
   */
  get description(): string {
    if (this._description) {
      return this._description
    }

    const PARAGRAPH_LENGTH_DEFAULT = 185

    const PARAGRAPH_LENGTH_MEDIUM = 250

    const showMoreLink = ' ... <a>Show More</a>'

    if (this._book.blurb === undefined) {
      Sentry.captureException(
        'Blurb of book [' + this.book.url_slug + '] not present',
      )
      return ''
    }

    const bookDescriptionParagraphs = this._book.blurb.split('</p>')

    const firstParagraph = bookDescriptionParagraphs.length
      ? bookDescriptionParagraphs[0] + '</p>'
      : ''

    if (
      platform.isMobileWidth() &&
      firstParagraph.length > PARAGRAPH_LENGTH_DEFAULT
    ) {
      return (
        firstParagraph?.substring(0, PARAGRAPH_LENGTH_DEFAULT) + showMoreLink
      )
    }

    if (
      platform.isMediumWidth() &&
      firstParagraph.length > PARAGRAPH_LENGTH_MEDIUM
    ) {
      return (
        firstParagraph?.substring(0, PARAGRAPH_LENGTH_MEDIUM) + showMoreLink
      )
    }

    if (platform.isTabletWidth()) {
      return firstParagraph.length > PARAGRAPH_LENGTH_DEFAULT
        ? firstParagraph?.substring(0, PARAGRAPH_LENGTH_DEFAULT) + showMoreLink
        : firstParagraph
    }

    return firstParagraph.length > PARAGRAPH_LENGTH_DEFAULT
      ? firstParagraph.substring(0, PARAGRAPH_LENGTH_DEFAULT) + showMoreLink
      : firstParagraph || ''
  }

  get book(): BookListItem {
    return this._book
  }

  get category(): string | undefined {
    const hasTags = this._book.tags.length
    return hasTags ? this._book.tags[0] : undefined
  }

  /**
   * Link for the book page.
   */
  get link(): Record<string, unknown> {
    return {
      name: 'book.preview',
      params: {
        url_slug: this._book.url_slug,
      },
    }
  }
}

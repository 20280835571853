import { ArticlesView } from './article'
import { DocsTagsView } from './docs.tags'
import { debounce } from '@/helpers/form'
import { ComponentPublicInstance } from 'vue'
import { emitter } from '@/services/mitt'
import { nextTick } from 'vue'
import { platform } from '@/services/platform'
import { PodcastsSearchView } from './search.podcasts'
import { BooksView } from './book'
import { PodcastEpisodesView } from './podcasts.episodes'
import { Mixpanel } from '@/analytics/mixpanel'
import { wait } from '@/helpers/vue-wait'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'

/**
 * Search view.
 *
 * Responsible for interaction between the Search Vue component and the
 * rest of models: books, articles, tags to filter them.
 */
export class SearchView {
  private _vm: ComponentPublicInstance

  public booksView: BooksView | null = null
  public articlesView: ArticlesView | null = null
  public tagsView: DocsTagsView | null = null
  public episodesView: PodcastEpisodesView | null = null
  public podcastsView: PodcastsSearchView | null = null

  public search: string = ''
  public prompt: string = ''
  public searchHandler = (): void => {}

  private hasFocus: boolean = true
  public showHeader: boolean = true
  public showTags: boolean = true
  private isIOS: boolean = false

  constructor(vm: ComponentPublicInstance, searchTerm: string = '') {
    this._vm = vm
    this.search = searchTerm
  }

  /**
   * Initialize search view.
   */
  async init(): Promise<void> {
    this._updateDocViews()

    this.searchHandler = debounce(this.searchHandlerCallback, 500)
    this.isIOS = await platform.isNativeIOSApp()
  }

  /**
   * Update the doc views.
   */
  private async _updateDocViews(): Promise<void> {
    const updateFunctions = [
      this._updateArticlesView.bind(this),
      this._updateBooksView.bind(this),
      this._updatePodcastEpisodesView.bind(this),
      this._updatePodcastsView.bind(this),
    ]

    // Run all functions asynchronously and store the promises in an array.
    const promises = updateFunctions.map((updateFunction) => updateFunction())
    const responses = await Promise.allSettled(promises)

    // Count failed requests.
    let failedViews = 0
    responses.forEach((response) => {
      if (response.status === 'rejected') {
        // If the response failed because it was canceled,
        // it was intentional. Don't capture the error.
        if (response.reason.code === 'ERR_CANCELED') {
          return
        }

        Sentry.captureException(response.reason)
        failedViews++
      }
    })

    // If all requests failed, display an error.
    if (failedViews >= updateFunctions.length) {
      wait.start(this._vm, 'Loading Search')
      wait.start(this._vm, 'Error Loading Search')
    }
  }

  public initEventListeners(): void {
    emitter.on('appScroll', () => {
      this.onAppScroll()
    })

    const searchRef = this._vm.$refs.search as any
    ;(searchRef.$el as HTMLElement).addEventListener(
      'focusout',
      (event: FocusEvent) => {
        this.focusOutHandler(event)
      },
    )
  }

  public focusOutHandler(event: FocusEvent): void {
    const searchValue = (event?.target as any).value
    // Send Page View event on un-focusing the search input.
    if (searchValue && searchValue !== '') {
      Mixpanel.pageView()
    }
  }

  /**
   * Search handler callback.
   *
   * Filters books, articles and tags views when the search string is updated.
   */
  searchHandlerCallback(): void {
    const searchTerm = this._vm.$route.query.term as string

    // We only reload data from backend when search has updated, no need to do that on initial search load (or page refresh)
    if (!searchTerm && !this.search) {
      return
    }

    if (searchTerm !== this.search) {
      this._updateDocViews()
    }

    // Show tags if the search input is empty.
    this.showTags = this.search === ''

    // If something was found, push the search string to the URL.
    if (
      this.booksView?.filteredDocs.length ||
      this.articlesView?.filteredDocs.length ||
      this.episodesView?.docs.length ||
      this.podcastsView?.podcasts.length
    ) {
      this.updateQueryString(this.search)
    }
  }

  /**
   * Update query string with the new search term.
   */
  updateQueryString(searchValue: string): void {
    this._vm.$router.push({ query: { term: searchValue } })
  }

  /**
   * Create a new instance of `ArticlesView` or filter it with
   * the current search term if it already exists.
   */
  private async _updateArticlesView(): Promise<void> {
    wait.start(this._vm, 'Articles')

    try {
      if (this.articlesView === null) {
        this.articlesView = await ArticlesView.createSearchView(this.search)
      } else {
        await this.articlesView.filter(this.search)
      }
    } catch (error: any) {
      // Rethrow the error so we can catch it in `this._updateDocViews()`.
      throw error
    } finally {
      wait.end(this._vm, 'Articles')
    }
  }

  /**
   * Create a new instance of `DocsTagsView`
   */
  private async _setupTagsView(): Promise<string[]> {
    wait.start(this._vm, 'Tags')

    try {
      const tags = await backend.getBookTags()
      this.tagsView = new DocsTagsView(tags, 'books', 'Book Categories')
      return tags
    } catch (error: any) {
      // Rethrow the error so we can catch it in `this._updateDocViews()`.
      throw error
    } finally {
      wait.end(this._vm, 'Tags')
    }
  }

  /**
   * Create a new instance of `BooksView` or filter it with
   * the current search term if it already exists.
   */
  private async _updateBooksView(): Promise<void> {
    wait.start(this._vm, 'Books')

    try {
      if (this.booksView === null) {
        const tags = await this._setupTagsView()
        this.booksView = await BooksView.createSearchView(tags, this.search)
      } else {
        await this.booksView.filter(this.search)
      }
    } catch (error: any) {
      // Rethrow the error so we can catch it in `this._updateDocViews()`.
      throw error
    } finally {
      wait.end(this._vm, 'Books')
    }
  }

  /**
   * Create a new instance of `PodcastsSearchView` or filter it with
   * the current search term if it already exists.
   */
  private async _updatePodcastsView(): Promise<void> {
    wait.start(this._vm, 'Podcasts')

    try {
      if (this.podcastsView === null) {
        this.podcastsView = new PodcastsSearchView()
        await this.podcastsView.init(this.search)
      } else {
        await this.podcastsView.updateSearchTerm(this.search)
      }
    } catch (error: any) {
      // Rethrow the error so we can catch it in `this._updateDocViews()`.
      throw error
    } finally {
      wait.end(this._vm, 'Podcasts')
    }
  }

  /**
   * Create a new instance of `PodcastEpisodesView` or filter it with
   * the current search term if it already exists.
   */
  private async _updatePodcastEpisodesView(): Promise<void> {
    wait.start(this._vm, 'Episodes')

    try {
      if (this.episodesView === null) {
        this.episodesView = await PodcastEpisodesView.createSearchView(
          this.search,
        )
      } else {
        await this.episodesView.filter(this.search)
      }
    } catch (error: any) {
      // Rethrow the error so we can catch it in `this._updateDocViews()`.
      throw error
    } finally {
      wait.end(this._vm, 'Episodes')
    }
  }

  get articlesViewAllLink(): string {
    return this.search && this.articlesView?.filteredDocs.length
      ? `/app/articles/search/${encodeURIComponent(this.search)}`
      : `/app/articles/list/all`
  }

  get booksViewAllLink(): string {
    return this.search
      ? `/app/books/search/${encodeURI(this.search)}`
      : '/app/books/list/popular'
  }

  get booksTitle(): string {
    return this.search ? 'Books' : 'Popular Books'
  }

  get episodesViewAllText(): string {
    return this.search ? 'View all' : 'View all podcasts'
  }

  get episodesViewAllLink(): string {
    return this.search
      ? `/app/podcasts/episodes/search/${this.search}`
      : '/app/podcasts'
  }

  get episodesTitle(): string {
    return 'Podcast Episodes'
  }

  /*
   * Show the empty podcasts block if books or articles have been found but no podcasts.
   */
  get showEmptyPodcastBlock(): boolean {
    return (
      (this.articlesFound || this.booksFound) &&
      !this.episodesFound &&
      !this.podcastsFound
    )
  }

  get booksFound(): boolean {
    return this.booksView !== null && this.booksView.filteredDocs.length > 0
  }

  get episodesFound(): boolean {
    if (!this.episodesView) {
      return false
    }
    return this.episodesView.filteredDocs.length > 0
  }

  get podcastsFound(): boolean {
    if (!this.podcastsView) {
      return false
    }
    return this.podcastsView.podcasts.length > 0
  }

  get articlesFound(): boolean {
    return (
      this.articlesView !== null && this.articlesView.filteredDocs.length > 0
    )
  }

  get showEmptyBlock(): boolean {
    return (
      !this.booksFound &&
      !this.articlesFound &&
      !this.podcastsFound &&
      !this.episodesFound
    )
  }

  get hasTags(): boolean {
    return this.tagsView !== null && this.tagsView.tags.length > 0
  }

  toggleFocus(): void {
    this.hasFocus = !this.hasFocus
  }

  clearSearchOnEscape(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.search = ''
    }
  }

  get headerClass(): any {
    return {
      'search__header--focus': !this.hasFocus || this.search,
      'search__header--ios': this.isIOS,
    }
  }

  /**
   * App scroll handler.
   *
   * When user scrolls on the mobile, we unfocus the search field to
   * hide a virtual keyboard.
   */
  private onAppScroll(): void {
    if (platform.isMobileWidth()) {
      nextTick(() => {
        this._vm.$refs.search &&
          ((this._vm.$refs.search as any).$el as HTMLElement).blur()
      })
    }

    // Close header User menu on scroll, otherwise it looks buggy
    emitter.emit('closeOverlayMenu')
  }
}

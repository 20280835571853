/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-this-alias */
'use strict'

import { Widget } from './widget'
import {
  gettext as _t,
  addEventHandler,
  elementData,
  escapeHtml,
  getHighlightCoords,
  removeEventHandler,
  selectParents,
} from './util'

import { Adder } from './adder'
import { Annotation } from '@/models/interfaces'

// Private: simple parser for hypermedia link structure
//
// Examples:
//
//   links = [
//     {
//       rel: 'alternate',
//       href: 'http://example.com/pages/14.json',
//       type: 'application/json'
//     },
//     {
//       rel: 'prev':
//       href: 'http://example.com/pages/13'
//     }
//   ]
//
//   parseLinks(links, 'alternate')
//   # => [{rel: 'alternate', href: 'http://...', ... }]
//   parseLinks(links, 'alternate', {type: 'text/html'})
//   # => []
//
function parseLinks(data: any, rel: any, cond: any) {
  cond = { ...cond, rel: rel }

  const results = []
  for (let i = 0, len = data.length; i < len; i++) {
    const d = data[i]
    let match = true

    for (const k in cond) {
      if (cond.hasOwnProperty(k) && d[k] !== cond[k]) {
        match = false
        break
      }
    }

    if (match) {
      results.push(d)
    }
  }

  return results
}

// Public: Creates an element for viewing annotations.
export const Viewer = Widget.extend({
  // Public: Creates an instance of the Viewer object.
  //
  // options - An Object containing options.
  //
  // Examples
  //
  //   # Creates a new viewer, adds a custom field and displays an annotation.
  //   viewer = new Viewer()
  //   viewer.addField({
  //     load: someLoadCallback
  //   })
  //   viewer.load(annotation)
  //
  // Returns a new Viewer instance.
  constructor: function (this: any, options: any) {
    Widget.call(this, options)

    this.itemTemplate = Viewer.itemTemplate
    this.fields = []
    this.annotations = []
    this.position = null
    this.hideTimer = null
    this.mouseDown = false
    this.render = function (annotation: any) {
      if (annotation.text) {
        return escapeHtml(annotation.text)
      } else {
        return '<i>' + _t('No note') + '</i>'
      }
    }

    // We use adder to handle the case when we have 1 annotation without
    // the note.
    // In this case we show adder with two buttons: `remove the highlight`
    // and `add a note`.
    const self = this
    this.adder = new Adder({
      onCreate: function (annotation: any, event: Event) {
        // When we show the adder from the viewer, the 'create' button
        // is used to remove existing highlight
        if (annotation.highlight_type === 'highlight') {
          return options.onDelete(annotation, event)
        }
        return options.onCreate(annotation, event)
      },
      onCopy: function (annotation: any, event: Event) {
        return options.onCopy(annotation, event)
      },
      onCreateNote: function (annotation: any, event: Event) {
        return options.onEdit(annotation, event)
      },
      onShare: function (where: string, annotation: any, event: Event) {
        return options.onShare(where, annotation, event)
      },
      onBookmark: function (annotation: any, event: Event) {
        // When we show the adder from the viewer, the 'create' button
        // is used to remove existing bookmark.
        if (annotation.highlight_type === 'bookmark') {
          return options.onDelete(annotation, event)
        }
        return options.onBookmark(annotation, event)
      },
    })
    this.adder.attach()

    if (this.options.defaultFields) {
      this.addField({
        load: function (field: any, annotation: any) {
          field.innerHTML = self.render(annotation)
        },
      })
    }

    if (typeof this.options.onEdit !== 'function') {
      throw new TypeError('onEdit callback must be a function')
    }
    if (typeof this.options.onShare !== 'function') {
      throw new TypeError('onShare callback must be a function')
    }
    if (typeof this.options.onDelete !== 'function') {
      throw new TypeError('onDelete callback must be a function')
    }
    if (typeof this.options.permitEdit !== 'function') {
      throw new TypeError('permitEdit callback must be a function')
    }
    if (typeof this.options.permitDelete !== 'function') {
      throw new TypeError('permitDelete callback must be a function')
    }

    if (this.options.autoViewHighlights) {
      this.document = this.options.autoViewHighlights.ownerDocument

      // `mouseout` triggers when the mouse leaves any element inside the event target.
      // We use it here because `addEventHandler()` attaches the event listener to the parent,
      // not the target we define, so `mouseleave` would not trigger when the mouse leaves our
      // actual target.
      // With `mouseout`, since it triggers on any element inside the target, we can check if
      // the element that triggered the event is the same as the target we defined.
      addEventHandler(
        this.options.autoViewHighlights,
        'mouseout',
        () => {
          self._startHideTimer()
        },
        '.annotator-hl',
      )
      addEventHandler(
        this.options.autoViewHighlights,
        'mouseover',
        (e, target) => {
          // If there are many overlapping highlights, still only
          // call _onHighlightMouseover once.
          // @ts-ignore
          if (e.target === target) {
            self._onHighlightMouseover(e)
          }
        },
        '.annotator-hl',
      )

      addEventHandler(this.document.body, 'mousedown', (e) => {
        if ((e as MouseEvent).which === 1) {
          self.mouseDown = true
        }
      })
      addEventHandler(this.document.body, 'mouseup', (e) => {
        if ((e as MouseEvent).which === 1) {
          self.mouseDown = false
        }
      })
    }

    addEventHandler(
      this.element,
      'click',
      (e) => {
        self._onEditClick(e)
      },
      '.annotator-edit',
    )
    addEventHandler(
      this.element,
      'click',
      (e) => {
        self._onShareClick('facebook', e)
      },
      '.annotator-share-fb',
    )
    addEventHandler(
      this.element,
      'click',
      (e) => {
        self._onShareClick('twitter', e)
      },
      '.annotator-share-twi',
    )
    addEventHandler(
      this.element,
      'click',
      (e) => {
        self._onShareClick('link', e)
      },
      '.annotator-share-link',
    )
    addEventHandler(
      this.element,
      'click',
      (e) => {
        self._onDeleteClick(e)
      },
      '.annotator-delete',
    )

    addEventHandler(this.element, 'mouseenter', () => {
      self._clearHideTimer()
    })
    addEventHandler(this.element, 'mouseleave', () => {
      self._startHideTimer()
    })

    // Add annotator-remover class that indicates that the first
    // button will be used to remove the highlight.
    this.adder.element.classList.add('annotator-remover')
    addEventHandler(this.adder.element, 'mouseenter', () => {
      self._clearHideTimer()
    })
    addEventHandler(this.adder.element, 'mouseleave', () => {
      self._startHideTimer()
    })
  },

  destroy: function () {
    if (this.options.autoViewHighlights) {
      removeEventHandler(this.options.autoViewHighlights, 'mouseout')
      removeEventHandler(this.options.autoViewHighlights, 'mouseover')

      removeEventHandler(this.document.body, 'mousedown')
      removeEventHandler(this.document.body, 'mouseup')
    }

    removeEventHandler(this.element, 'click')
    removeEventHandler(this.element, 'mouseenter')
    removeEventHandler(this.element, 'mouseleave')

    removeEventHandler(this.adder.element, 'mouseenter')
    removeEventHandler(this.adder.element, 'mouseleave')

    Widget.prototype.destroy.call(this)
    Widget.prototype.destroy.call(this.adder)
  },

  // Public: Show the viewer.
  //
  // position - An Object specifying the position in which to show the editor
  //
  // Examples
  //
  //   viewer.show()
  //   viewer.hide()
  //   viewer.show({top: '100px', left: '80px'})
  //
  // Returns nothing.
  show: function (position: any) {
    const controls = this.element.querySelector('.annotator-controls')

    controls.classList.add(this.classes.showControls)

    const self = this
    setTimeout(function () {
      controls.classList.remove(self.classes.showControls)
    }, 500)

    Widget.prototype.show.call(this, position)
  },

  // Public: Hide the widget.
  //
  // Returns nothing.
  hide: function () {
    this.mouseDown = false
    this.adder.hide()
    Widget.prototype.hide.call(this)
  },

  // Public: Load annotations into the viewer and show it.
  //
  // annotation - An Array of annotations.
  //
  // Examples
  //
  //   viewer.load([annotation1, annotation2, annotation3])
  //
  // Returns nothing.
  load: function (annotations: any, position: any) {
    this.annotations = annotations || []
    this.position = position

    if (this.annotations.length === 1 && !this.annotations[0].text) {
      // Hide adder / viewer if they are shown.
      this.adder.load(this.annotations[0], position)
      return
    }

    const list = this.element.querySelector('ul')
    list.replaceChildren()

    for (let i = 0, len = this.annotations.length; i < len; i++) {
      const annotation = this.annotations[i]
      const annotationItem = this._annotationItem(annotation)

      list.appendChild(annotationItem)
      elementData.set(annotationItem, 'annotation', annotation)
    }

    this.show(position)
  },

  // Public: Set the annotation renderer.
  //
  // renderer - A function that accepts an annotation and returns HTML.
  //
  // Returns nothing.
  setRenderer: function (renderer: any) {
    this.render = renderer
  },

  // Private: create the list item for a single annotation
  _annotationItem: function (annotation: any) {
    const item = this.itemTemplate.cloneNode(true)

    const controls = item.querySelector('.annotator-controls')
    const link = controls.querySelector('.annotator-link')
    const edit = controls.querySelector('.annotator-edit')
    const del = controls.querySelector('.annotator-delete')

    const links = parseLinks(annotation.links || [], 'alternate', {
      type: 'text/html',
    })
    const hasValidLink =
      links.length > 0 &&
      typeof links[0].href !== 'undefined' &&
      links[0].href !== null

    if (hasValidLink) {
      link.href = links[0].href
    } else {
      link.remove()
    }

    const controller: any = {}
    if (this.options.permitEdit(annotation)) {
      controller.showEdit = function () {
        edit.disabled = false
      }
      controller.hideEdit = function () {
        edit.disabled = true
      }
    } else {
      edit.remove()
    }
    if (this.options.permitDelete(annotation)) {
      controller.showDelete = function () {
        del.disabled = false
      }
      controller.hideDelete = function () {
        del.disabled = true
      }
    } else {
      del.remove()
    }
    for (let i = 0, len = this.fields.length; i < len; i++) {
      const field = this.fields[i]
      const element = field.element.cloneNode(true)
      item.prepend(element)
      field.load(element, annotation, controller)
    }

    return item
  },

  // Public: Adds an addional field to an annotation view. A callback can be
  // provided to update the view on load.
  //
  // options - An options Object. Options are as follows:
  //           load - Callback Function called when the view is loaded with an
  //                  annotation. Recieves a newly created clone of an item
  //                  and the annotation to be displayed (it will be called
  //                  once for each annotation being loaded).
  //
  // Examples
  //
  //   # Display a user name.
  //   viewer.addField({
  //     # This is called when the viewer is loaded.
  //     load: (field, annotation) ->
  //       field = $(field)
  //
  //       if annotation.user
  //         field.text(annotation.user) # Display the user
  //       else
  //         field.remove()              # Do not display the field.
  //   })
  //
  // Returns itself.
  addField: function (options: any) {
    const defaultOptions = {
      load: function () {},
    }
    const field = { ...defaultOptions, ...options }

    field.element = document.createElement('div')
    this.fields.push(field)
    return this
  },

  // Event callback: called when the edit button is clicked.
  //
  // event - An Event object.
  //
  // Returns nothing.
  _onEditClick: function (event: Event) {
    // @ts-ignore
    const item = event.target.parentNode.closest('.annotator-annotation')
    const itemData = elementData.get(item, 'annotation')
    this.hide()
    this.options.onEdit(itemData, event)
  },

  // Event callback: called when the share button is clicked.
  //
  // event - An Event object.
  //
  // Returns nothing.
  _onShareClick: function (where: string, event: Event) {
    // @ts-ignore
    const item = event.target.parentNode.closest('.annotator-annotation')
    const itemData = elementData.get(item, 'annotation')
    this.hide()
    this.options.onShare(where, itemData, event)
  },

  // Event callback: called when the delete button is clicked.
  //
  // event - An Event object.
  //
  // Returns nothing.
  _onDeleteClick: function (event: Event) {
    // @ts-ignore
    const item = event.target.parentNode.closest('.annotator-annotation')
    const itemData = elementData.get(item, 'annotation')
    this.hide()
    this.options.onDelete(itemData, event)
  },

  // Event callback: called when a user triggers `mouseover` on a highlight
  // element.
  //
  // event - An Event object.
  //
  // Returns nothing.
  _onHighlightMouseover: function (event: MouseEvent) {
    // If the mouse button is currently depressed, we're probably trying to
    // make a selection, so we shouldn't show the viewer.
    if (this.mouseDown) {
      return
    }

    // If this highlight doesn't have an ID, it is a new one being edited and
    // should not open an adder.
    const target = event.target as HTMLElement
    if (target && !target.hasAttribute('data-annotation-id')) {
      return
    }

    // If the adder popover is open, don't open the viewer.
    const adderElement = document.querySelector(
      '.annotator-adder:not(.annotator-remover)',
    )
    if (adderElement && !adderElement.classList.contains('annotator-hide')) {
      return
    }

    const self = this

    self._clearHideTimer()
    self.hide()

    const annotationElements = selectParents(target, true, '.annotator-hl')
    const annotations = annotationElements.map((element: HTMLElement) => {
      return elementData.get(element, 'annotation')
    })
    const containerSelector = this.options.appendTo

    const annotation = elementData.get(
      annotationElements[0],
      'annotation',
    ) as Annotation

    // Add custom class if this is a bookmark highlight
    if (annotation.highlight_type === 'bookmark') {
      this.adder.element.classList.add('annotator-remover-bookmark')
    } else {
      this.adder.element.classList.remove('annotator-remover-bookmark')
    }

    // @ts-ignore
    const highlights = annotations[0]._local.highlights as HTMLElement[]

    const annotationPosition = getHighlightCoords(highlights, containerSelector)

    // Now show the viewer with the wanted annotations
    self.load(annotations, annotationPosition)
  },

  // Starts the hide timer. This returns a promise that is resolved when the
  // viewer has been hidden. If the viewer is already hidden, the promise will
  // be resolved instantly.
  //
  // activity - A boolean indicating whether the need to hide is due to a user
  //            actively indicating a desire to view another annotation (as
  //            opposed to merely mousing off the current one). Default: false
  //
  // Returns a Promise.
  _startHideTimer: function (activity: any) {
    if (typeof activity === 'undefined' || activity === null) {
      activity = false
    }

    if (this.hideTimer) {
      clearTimeout(this.hideTimer)
    }

    let timeout
    if (activity) {
      timeout = this.options.activityDelay
    } else {
      timeout = this.options.inactivityDelay
    }

    this.hideTimer = setTimeout(() => {
      this.hide()
    }, timeout)
  },

  // Clears the hide timer. Also rejects any promise returned by a previous
  // call to _startHideTimer.
  //
  // Returns nothing.
  _clearHideTimer: function () {
    clearTimeout(this.hideTimer)
  },
})

// Classes for toggling annotator state.
Viewer.classes = {
  showControls: 'annotator-visible',
}

// HTML template for this.widget.
Viewer.template = document.createElement('div')
Viewer.template.classList.add(
  'annotator-outer',
  'annotator-viewer',
  'annotator-hide',
)
Viewer.template.innerHTML =
  '<ul class="annotator-widget annotator-listing"></ul>'

// HTML template for this.item properties
Viewer.itemTemplate = document.createElement('li')
Viewer.itemTemplate.classList.add('annotator-annotation', 'annotator-item')
Viewer.itemTemplate.innerHTML = `
  <hr class="annotator-divider">
  <span class="annotator-controls annotator-controls-left">
    <a href="#"
       title="${_t('View as webpage')}"
       class="annotator-link">${_t('View as webpage')}</a>
    <button type="button"
            title="${_t('Edit')}"
            class="annotator-edit iconfont iedit"></button>
    <button type="button"
            title="${_t('Share: Facebook')}"
            class="annotator-share-fb iconfont ifacebook-1"></button>
    <button type="button"
            title="${_t('Share: Twitter')}"
            class="annotator-share-twi iconfont itwitter-1"></button>
    <button type="button"
            title="${_t('Share: Link')}"
            class="annotator-share-link iconfont ishare-1"></button>
    <button type="button"
            title="${_t('Delete')}"
            class="annotator-delete iconfont itext-edit-06"></button>
  </span>
`

// Configuration options
Viewer.options = {
  // Add the default field(s) to the viewer.
  defaultFields: true,

  // Time, in milliseconds, before the viewer is hidden when a user mouses off
  // the viewer.
  inactivityDelay: 500,

  // Time, in milliseconds, before the viewer is updated when a user mouses
  // over another annotation.
  activityDelay: 100,

  // Hook, passed an annotation, which determines if the viewer's "edit"
  // button is shown. If it is not a function, the button will not be shown.
  permitEdit: function () {
    return false
  },

  // Hook, passed an annotation, which determines if the viewer's "delete"
  // button is shown. If it is not a function, the button will not be shown.
  permitDelete: function () {
    return false
  },

  // If set to a DOM Element, will set up the viewer to automatically display
  // when the user hovers over Annotator highlights within that element.
  autoViewHighlights: null,

  // Callback, called when the user clicks the edit button for an annotation.
  onEdit: function () {},

  // Callback, called when the user clicks the edit button for an annotation.
  onShare: function () {},

  // Callback, called when the user clicks the delete button for an
  // annotation.
  onDelete: function () {},
}

// The standalone is a module that uses the Viewer to display an viewer widget in
// response to some viewer action (such as mousing over an annotator highlight
// element).
export function standalone(options: any): Record<string, unknown> {
  let widget: any

  if (typeof options === 'undefined' || options === null) {
    options = {}
  }

  return {
    start: function (app: any) {
      const ident = app.registry.getUtility('identityPolicy')
      const authz = app.registry.getUtility('authorizationPolicy')

      // Set default handlers for what happens when the user clicks the
      // edit and delete buttons:
      if (typeof options.onEdit === 'undefined') {
        options.onEdit = function (annotation: any) {
          app.annotations.update(annotation)
        }
      }
      if (typeof options.onShare === 'undefined') {
        options.onShare = function (
          where: string,
          annotation: any,
          event: Event,
        ) {
          app.annotations.share(where, annotation, event)
        }
      }
      if (typeof options.onDelete === 'undefined') {
        options.onDelete = function (annotation: any) {
          app.annotations['delete'](annotation)
        }
      }

      // Set default handlers that determine whether the edit and delete
      // buttons are shown in the viewer:
      if (typeof options.permitEdit === 'undefined') {
        options.permitEdit = function (annotation: any) {
          return authz.permits('update', annotation, ident.who())
        }
      }
      if (typeof options.permitDelete === 'undefined') {
        options.permitDelete = function (annotation: any) {
          return authz.permits('delete', annotation, ident.who())
        }
      }

      widget = new Viewer(options)
    },

    destroy: function () {
      widget.destroy()
    },
  }
}

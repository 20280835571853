/* eslint-disable @typescript-eslint/no-this-alias */
'use strict'

// @ts-ignore
import { Range } from '../../xpath-range/lib'

import { addEventHandler, removeEventHandler, selectParents } from './util'
import { isIOS, isSafari } from '@/helpers/userAgent'
import { platform } from '@/services/platform'

export interface TextSelector {
  element: any
  options: TextSelectorOptions
  onSelection: TextSelectorOptions['onSelection']
  onSelectionStarted: TextSelectorOptions['onSelectionStarted']
  onSelectionInProgress: TextSelectorOptions['onSelectionInProgress']
}

export interface TextSelectorOptions extends Record<any, any> {
  /**
   * Callback called when the user starts making a selection.
   * Receives the DOM Event that was detected as a selection.
   */
  onSelectionStarted: (() => void) | null
  /**
   * Callback called when the user is making a selection.
   * Receives the DOM Event that was detected as a selection.
   */
  onSelectionInProgress: (() => void) | null
  /**
   * Callback, called when the user makes a selection.
   * Receives the list of selected ranges (may be empty) and  the DOM Event
   * that was detected as a selection.
   */
  onSelection: ((ranges: any) => void) | null
}

// Configuration options
const defaultOptions: TextSelectorOptions = {
  onSelection: null,
  onSelectionInProgress: null,
  onSelectionStarted: null,
}

// The isAnnotator determines if the provided element is part of Annotator. Useful
// for ignoring mouse actions on the annotator elements.
//
// element - An Element or TextNode to check.
//
// Returns true if the element is a child of an annotator element.
function isAnnotator(element: any) {
  const elAndParents = selectParents(element, true)
  const annotatorElements = elAndParents.filter((item: HTMLElement) => {
    return item.matches('[class^=annotator-]')
  })
  return annotatorElements.length !== 0
}

// TextSelector monitors a document (or a specific element) for text selections
// and can notify another object of a selection event
export const TextSelector = function (
  this: any,
  element: HTMLElement,
  options: TextSelectorOptions,
): any {
  const defaultOptionsCopy = JSON.parse(JSON.stringify(defaultOptions))

  this.element = element
  this.options = { ...defaultOptionsCopy, ...options }
  this.onSelection = this.options.onSelection
  this.onSelectionStarted = this.options.onSelectionStarted
  this.onSelectionInProgress = this.options.onSelectionInProgress

  this.selectionChangeTimer = null
  this.lastSelection = null
  this.selectionStarted = false

  if (
    typeof this.element.ownerDocument !== 'undefined' &&
    this.element.ownerDocument !== null
  ) {
    const self = this
    this.document = this.element.ownerDocument

    // We have the _checkForEndSelection() call in the `selectionchange` handler
    // below.
    // So we had double _checkForEndSelection call on desktop - no problems found
    // because of this, but still it looks inefficient and error-prune.
    // We can uncomment code below if any problems are found with `selectionchange` method.
    //
    // $(this.document.body).on('mouseup.' + TEXTSELECTOR_NS, function(
    //   e: MouseEvent,
    // ) {
    //   // console.log('mouseup')
    //   // @ts-ignore
    //   const sel = global.getSelection()
    //   const selText = sel ? sel.toString() : null
    //   // Update lastSelection, so 'selectionchange' handler knows
    //   // there is no need to check for selection once more.
    //   self.lastSelection = selText
    //   self._checkForEndSelection(e)
    // })

    // We don't clear the text selection on mousedown for mobile,
    // because it interrupts the selection process. But onclick
    // is called when a selection is not happening and then we can clear the selection.
    const highlightsContent = document.querySelector(
      '.highlights-content.prevent-content-drag',
    ) as HTMLElement

    addEventHandler(highlightsContent, 'click', (event) => {
      // Prevent the function from running when clicking the annotation
      // note field, which removes the focus from the element, making it impossible
      // to use.
      const eventTarget = event?.target as HTMLElement
      const eventTargetType = eventTarget.tagName.toLowerCase()
      if (eventTargetType === 'input' || eventTargetType === 'textarea') {
        return
      }

      // On desktop/web the clearing the selection onclick causes the highlights
      // to disappear immediately, so we check if an highlight started before
      // clearing the selection
      if (!this.selectionStarted) {
        this.clearSelection()
      }
    })

    /**
     * Clear the selection when the user starts dragging on the text.
     * This prevents the text from being copied by dragging it into a
     * text editor.
     */
    addEventHandler(highlightsContent, 'pointerdown', () => {
      // On a pointerdown event, the text selection process resets,
      // so we tag selection as not started.
      this.selectionStarted = false

      if (!platform.isTouchScreen()) {
        // Clear text selection. This prevents copying by dragging the
        // selected text to another editor. On mobile, this would prevent
        // users from using the selection handles to alter the selection
        // range, so we only do it on desktop.
        this.clearSelection()
      }
    })

    addEventHandler(highlightsContent, 'mousedown', (event) => {
      // Sometimes right after selecting text on iOS, it will fire a `click` event
      // (and `mousedown` and `mouseup`), which will clear the selection. We don't
      // know exactly why this happens, but here we prevent the default behavior of
      // the `mousedown` event, which would clear the selection.
      // We also check if there's a selection in progress, because otherwise it would
      // prevent the user from clearing the selection by tapping out of it.
      if (isIOS() && this.selectionStarted) {
        event?.preventDefault()
        return
      }
    })

    document.addEventListener('selectionchange', () => {
      // Mouse up doesn't work on mobile because of the default long hold handler:
      // when you tap and hold, the browser starts the selection.
      // Probably, under the hood, it handles same regular javascript evetns:
      // touchstart, touchend, etc.
      //
      // In this case we receive mousedown and touchstart events, but we don't receive
      // mouseup and touchend. We can do e.preventDefault() in 'touchstart', but
      // then we loose the default text selection behavior, so if we prevent default
      // behavior, we also have to reimplement it and handle text selection
      // programmatically.
      // So it is easier (at least for now) to bind to 'selectionchange' which seems
      // to be supported by all major browsers:
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/selectionchange_event
      //
      // Current implementation also means that on desktop we have _checkForEndSelection
      // called twice:
      // - First: the mouseup handler
      // - Second: the selectionchange handler below
      // This doesn't produce any problems, the adder UI remains in the same position
      // where it is opened already.
      // Note: for now, the mouseup handler is commented out, so here we have an only
      // call of _checkForEndSelection.
      //
      // On mobile, the flow is this:
      // - We select the text
      // - The standard context menu is prevented by oncontextmenu handler
      // - After 0.5 sec (the timer delay below) the adder UI is shown
      //
      // Related information:
      // - https://stackoverflow.com/questions/11237936/mobile-web-disable-long-touch-taphold-text-selection
      // - https://stackoverflow.com/questions/14486804/understanding-touch-events
      // - https://stackoverflow.com/questions/6139225/how-to-detect-a-long-touch-pressure-with-javascript-for-android-and-iphone
      // - https://patrickhlauke.github.io/touch/tests/results/
      // - https://lisilinhart.info/posts/touch-interaction-vue
      // - https://developer.mozilla.org/en-US/docs/Web/API/Touch_events
      // - https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Using_Touch_Events
      // - https://developer.mozilla.org/en-US/docs/Web/API/Element/contextmenu_event

      // @ts-ignore
      const sel = global.getSelection()

      if (sel?.type === 'Range') {
        // Detect if a highlight selection process has started.
        // The 'selectionchange' event fires multiple times while selection is in progress,
        // so we keep track of the first instance the event was triggered and prevent other triggers.
        if (!this.selectionStarted) {
          this.selectionStarted = true
          self._checkForStartSelection()
        }

        // Detect if a highlight selection process is in progress.
        // We check if there's a difference betweeb the last selection
        // and the current selection gotten from the 'selectionchange'
        const selText = sel ? sel.toString() : null
        if (this.lastSelection !== selText) {
          this._checkForSelectionInProgress()
        }
      }

      if (this.selectionChangeTimer) {
        clearTimeout(this.selectionChangeTimer)
      }
      this.selectionChangeTimer = setTimeout(() => {
        const selText = sel ? sel.toString() : null
        // The 'selectionchange' keeps fireing while the selection is active,
        // we only want to handle it once, so we check if selection has actually
        // changed here.
        if (this.lastSelection !== selText) {
          this.lastSelection = selText
          self._checkForEndSelection()
        }
      }, 500)
    })

    window.document.addEventListener('contextmenu', (e: MouseEvent) => {
      if (e.buttons === 0) {
        // Prevent the standard context menu on mobile,
        // here we have both e.button and e.buttons = 0
        // (on desktop we have e.button === 2 for right click).
        e.preventDefault()
      }
    })

    /*
    Debug:
    window.addEventListener('touchstart', (e) => { console.log('start', e) }, false)
    window.addEventListener('touchmove', (e) => { console.log('move', e) }, false)
    window.addEventListener('touchend', (e) => { console.log('end', e) }, false)
    window.addEventListener('touchcancel', (e) => { console.log('end', e) }, false)
    */
  } else {
    /* eslint-disable no-console */
    console.warn(
      'You created an instance of the TextSelector on an ' +
        "element that doesn't have an ownerDocument. This won't " +
        'work! Please ensure the element is added to the DOM ' +
        'before the plugin is configured:',
      this.element,
    )
    /* eslint-enable no-console */
  }
} as any as {
  new (element: HTMLElement, options: TextSelectorOptions): TextSelector
}

TextSelector.prototype.destroy = function () {
  if (this.document) {
    const highlightsContent = document.querySelector(
      '.highlights-content.prevent-content-drag',
    ) as HTMLElement

    removeEventHandler(highlightsContent, 'click')
    removeEventHandler(highlightsContent, 'mousedown')
    removeEventHandler(highlightsContent, 'pointerdown')
  }
}

// Public: capture the current selection from the document, excluding any nodes
// that fall outside of the adder's `element`.
//
// Returns an Array of NormalizedRange instances.
TextSelector.prototype.captureDocumentSelection = function () {
  const ranges = [],
    rangesToIgnore = [],
    // @ts-ignore
    selection = global.getSelection()

  if (selection.isCollapsed) {
    return []
  }

  for (let i = 0; i < selection.rangeCount; i++) {
    const r = selection.getRangeAt(i)
    const browserRange = new Range.BrowserRange(r)
    const normedRange = browserRange.normalize().limit(this.element)

    // If the new range falls fully outside our this.element, we should
    // add it back to the document but not return it from this method.
    if (normedRange === null) {
      rangesToIgnore.push(r)
    } else {
      ranges.push(normedRange)
    }
  }

  // BrowserRange#normalize() modifies the DOM structure and deselects the
  // underlying text as a result. So here we remove the selected ranges and
  // reapply the new ones.
  //
  // Note: the above is not true in general - the text remains selected after
  // using `normalize`, maybe there is some special case, when text becomes
  // deselected - we will need to re-enable the code below if such case is found,
  // but only for this specific case (or cases).
  //
  // The problem of removing and re-adding the selection is that on Android
  // the selection anchors disappear, so it is not possible to extend or
  // reduce the selection. This is the reason to comment out this part of code.
  //
  // Note 2: the special case is found - this is Safari, where selection disappears
  // after first attempt (try selecting some word - selection disappears, select it
  // again - selection remains, proably because `normalize` doesn't need to modify the
  // document structure on the second time).
  //
  //
  if (isSafari() || isIOS()) {
    selection.removeAllRanges()

    for (let i = 0, len = rangesToIgnore.length; i < len; i++) {
      selection.addRange(rangesToIgnore[i])
    }

    // Add normed ranges back to the selection
    for (let i = 0, len = ranges.length; i < len; i++) {
      const range = ranges[i],
        drange = this.document.createRange()
      drange.setStartBefore(range.start)
      drange.setEndAfter(range.end)
      selection.addRange(drange)
    }
  }

  return ranges
}

// Clear text selection
TextSelector.prototype.clearSelection = function () {
  // @ts-ignore
  const sel = global.getSelection()

  if (sel) {
    if (sel.removeAllRanges) {
      sel.removeAllRanges()
    } else if (sel.empty) {
      sel.empty()
    }
  }
}

// Event callback: called when the mouse button is released. Checks to see if a
// selection has been made and if so displays the adder.
//
// event - A mouseup Event object.
//
// Returns nothing.
TextSelector.prototype._checkForEndSelection = function (
  event: MouseEvent | null = null,
) {
  const self = this

  const _nullSelection = function () {
    if (typeof self.onSelection === 'function') {
      self.onSelection([], event)
    }
  }

  // Get the currently selected ranges.
  const selectedRanges = this.captureDocumentSelection()

  if (selectedRanges.length === 0) {
    _nullSelection()
    return
  }

  // Don't show the adder if the selection was of a part of Annotator itself.
  for (let i = 0, len = selectedRanges.length; i < len; i++) {
    let container = selectedRanges[i].commonAncestor
    if (container.classList.contains('annotator-hl')) {
      container = container.parentNode.closest('[class]:not(.annotator-hl)')
    }
    if (isAnnotator(container)) {
      _nullSelection()
      return
    }
  }

  if (typeof this.onSelection === 'function') {
    this.onSelection(selectedRanges, event)
  }
}

// Event callback: called when a selection has started
TextSelector.prototype._checkForStartSelection = function (
  event: MouseEvent | null = null,
) {
  if (typeof this.onSelectionStarted === 'function') {
    this.onSelectionStarted(event)
  }
}

// Event callback: called when a selection is in progress
TextSelector.prototype._checkForSelectionInProgress = function (
  event: MouseEvent | null = null,
) {
  if (typeof this.onSelectionInProgress === 'function') {
    this.onSelectionInProgress(event)
  }
}


import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'
import { RouteLocationNormalized } from 'vue-router'
// Note: docs say (https://github.com/vuejs/vue-class-component#adding-custom-hooks)
// that it should be enough to import class-component-hooks only in the main.ts,
// but, for some reason, that didn't work and I also have to import here.
//
// Related issues:
//  https://github.com/vuejs/vue-class-component/issues/264
//  https://github.com/vuejs/vue-class-component/issues/261
//
import { initClassComponentHooks } from '@/init/class-component-hooks'
initClassComponentHooks()

import { backend } from '@/services/backend'

import { wait } from '@/helpers/vue-wait'
import { Sentry } from '@/services/sentry'
import { assertNotNull } from '@/helpers/typing'

import { BookView } from '@/models/book'
import { backLink } from '@/models/backlink'
import { aiLabel13537Experiment } from '@/services/ab'

import { offline } from '@/services/offline'
import { loadBookDetailFromNativeStorage } from '@/services/native.storage'
import DocSummaryPage from '@/app/components/doc/DocSummaryPage.vue'

@Options({
  components: {
    DocSummaryPage,
  },
})
export default class BookSummaryPage extends Vue {
  book: BookView | null = null

  async beforeRouteLeave(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: any,
  ): Promise<void> {
    // Called when the route that renders this component is about to
    // be navigated away from.
    // Has access to `this` component instance.
    if (this.book) {
      await this.book.updateReadTimeAndSavePosition()
      backLink.value.updateMetaTitle(from, this.book.title)
    }
    next()
  }

  async beforeRouteUpdate(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: any,
  ): Promise<void> {
    const book = assertNotNull(this.book)

    book.initPage(to, from, this.loadDocument)
    backLink.value.updateMetaTitle(from, book.title)

    next()
  }

  async beforeMount(): Promise<void> {
    await this.loadDocument(this.$route)

    if (this.book && this.book.is_ai) {
      await aiLabel13537Experiment()
    }
  }

  async _fetchDocument(route: RouteLocationNormalized): Promise<BookView> {
    if (offline.isOffline) {
      await loadBookDetailFromNativeStorage(route.params.url_slug as string)
    }
    const response = await backend.getBook(route.params.url_slug as string)
    const doc = new BookView(this, response)
    doc.getPageFromRoute(route)
    this.book = doc
    return this.book
  }

  async loadDocument(route: RouteLocationNormalized): Promise<void> {
    try {
      wait.start(this, 'Loading Doc')
      wait.end(this, 'Error Loading Doc')

      this.book = await this._fetchDocument(route)

      // Disabling the restoring when accessing the page from highligh page
      const restorePositionByReadingProgress =
        route.query?.highlight === undefined
      this.book.initDocument(restorePositionByReadingProgress)

      if (!this.book.isPreviewPage) {
        await this.book.scrollToTop()
      }
    } catch (error: any) {
      if (error.request && error.request.status === 404) {
        // Redirect to the non-existing page
        this.$router.push({ name: 'not_found' })
      } else {
        wait.start(this, 'Error Loading Doc')
        Sentry.captureException(error)
      }
    } finally {
      wait.end(this, 'Loading Doc')
    }
  }

  get isLoadingDoc(): boolean {
    return wait.is(this, 'Loading Doc')
  }

  get isErrorLoadingDoc(): boolean {
    return wait.is(this, 'Error Loading Doc')
  }
}


import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { SocialAuthView } from '@/models/auth/social'
import { GoogleAuthView, AuthMode } from '@/models/auth/google'
import { GoogleNativeAuthView } from '@/models/auth/google.native'
import { GOOGLE_CLIENT_ID } from '@/init/settings'

@Options({})
export default class GoogleSignInButton extends Vue {
  @Prop() private view!: SocialAuthView
  @Prop() private authMode!: AuthMode
  @Prop({ default: 'all' }) private showButton!: 'small' | 'big' | 'all'

  public clientId = GOOGLE_CLIENT_ID
  public isNativeApp = false

  beforeMount(): void {
    this.isNativeApp = this.view instanceof GoogleNativeAuthView
  }

  async mounted(): Promise<void> {
    if (this.view instanceof GoogleAuthView) {
      await this.view.containerComponentHasMounted(this.authMode)
    }
  }

  async login(): Promise<void> {
    if (this.view instanceof GoogleNativeAuthView) {
      await this.view.start()
    }
  }
}

<template>
  <div class="skeleton-article">
    <div class="skeleton-article__cover"></div>
    <div class="skeleton-article__lower">
      <div class="skeleton-article__tag"></div>
      <div class="skeleton-article__title">
        <div class="skeleton-article__title-line"></div>
        <div class="skeleton-article__title-line"></div>
      </div>
      <div class="skeleton-article__date"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets.app/css/common/animations.scss';

.skeleton-article {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 390px;
  overflow: hidden;
  border-radius: 12px;
  background: $c-white;
  border: 1px solid $c-border;
  @media only screen and (max-width: $w-tablet-s) {
    height: 315px;
  }

  &__cover {
    width: 100%;
    height: 202px;
    animation: skeleton-loading 1s linear infinite alternate;
  }

  &__lower {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
  }

  &__tag {
    width: 50%;
    height: 15px;
    border-radius: 4px;
    margin-bottom: 26px;
    animation: skeleton-loading 1s linear infinite alternate;
  }

  &__title-line {
    width: 100%;
    height: 24px;
    border-radius: 4px;
    margin-bottom: 8px;
    animation: skeleton-loading 1s linear infinite alternate;
    @media only screen and (max-width: $w-tablet-s) {
      height: 20px;
      margin-bottom: 6px;
    }

    &:last-child {
      width: 75%;
      margin-bottom: 16px;
    }
  }

  &__date {
    width: 25%;
    height: 24px;
    border-radius: 4px;
    margin-top: auto;
    animation: skeleton-loading 1s linear infinite alternate;
    @media only screen and (max-width: $w-tablet-s) {
      height: 20px;
    }
  }
}

.night-mode .skeleton-article {
  background: $c-dark-1;
  border-color: $c-dark-3;

  &__cover,
  &__title-line,
  &__tag,
  &__date {
    animation: skeleton-loading-night-mode 1s linear infinite alternate;
  }
}
</style>
